'use strict';

module.exports = /*@ngInject*/ function($scope, $rootScope, formType,AuthService, $log, Facebook) {
	var vm = this;


	/**
	* Watch for Facebook to be ready.
	* There's also the event that could be used
	*/
	$scope.$watch(
		function() {	
			return Facebook.isReady();
		},
		function(newVal) {
			if (newVal){
				$scope.facebookReady = true;
			}
		}
	);
	/*
	var userIsConnected = false;
	
	Facebook.getLoginStatus(function(response) {
		if (response.status == 'connected') {
			userIsConnected = true;
			$log.log("connected");
		}
	});
  	*/

	vm.fbLogin = function() {

		Facebook.login(function(response) {
			if (response.status == 'connected') {
				$scope.logged = true;
				AuthService.fbLoginCheck(response.authResponse.accessToken).then(function(user){
					$rootScope.currentUser = user;
					$scope.$close(user, false);
				});
			}
		});

	};





	vm.cancel = $scope.$dismiss;
	
	vm.fblink = '#';

	AuthService.getFbLink().then(function(data){
		vm.fblink = data;
	});
	


	vm.onLogin = function(user) {
		$rootScope.currentUser = user;
		$scope.$close(user, false);
	};

	vm.onRegister = function(user) {
		$rootScope.currentUser = user;
		$scope.$close(user, true);
	};

	vm.onPassRequest = function() {
		$scope.$dismiss();
	};



	if (formType === 'register') {
		vm.activeTab = 1;
	}

};