'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		subscriptionCat: '=flxSubPlansTable',
		onSelect: '&'
	};

	directive.controller = 'SPTableCtrl';

	directive.controllerAs = 'sptCtrl';

	directive.templateUrl = 'flxSubPlansTable/flxSubPlansTable.tpl.html';

	return directive;
};
