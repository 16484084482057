'use strict';

var angular = require('angular');
var searchController = require('./searchController');
var states = require('./searchStates');


module.exports = angular
	.module('states.search', [
		'flxShowThumb',
		'showService',
		'flxPlayerModal',
		'channelService'
	])
	.controller('SearchCtrl', searchController)
	.config(states);