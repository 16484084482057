'use strict';

module.exports = /*@ngInject*/ function($scope, $state, customerSubscriptionSrv) {
	var vm = this;

	vm.renew = function(subscription) {
		if (subscription.status === true) { return; }
		// Only expired subscriptions can be renew
		$state.go('user.subscribe', {plan: subscription.plan});
	};
	
	vm.cancel = function(subscription, toggle) {
		if (subscription.status === false) { return; }
		// Only active subscriptions can be canceled
		if (subscription.cancelationRequestTime!=null) {
			customerSubscriptionSrv.undoCancel(subscription.id);
			subscription.cancelationRequestTime=null;
		} else {
			customerSubscriptionSrv.sendCancel(subscription.id);
			subscription.cancelationRequestTime=1;
		}
	};
/*
	vm.cancel = function(subscription){
		if (subscription.status === false) { return; }
		subscription.cancelationRequestTime=1;
		customerSubscriptionSrv.sendCancel(subscription.id);
	};

	vm.undoCancel = function(subscription){
		if (subscription.status === false) { return; }
		subscription.cancelationRequestTime=null;
		customerSubscriptionSrv.undoCancel(subscription.id);
	};*/


};