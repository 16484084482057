'use strict';

var angular = require('angular');

/*@ngInject*/
function braintreeSrv($http, $q, $ocLazyLoad, $log) {
	var service = {};

	service.loadJsFile = function(url) {
		return $ocLazyLoad.load(url);
	};

	service.tokenizeCard = function(card, clientTokenFromServer) {
		var deferred = $q.defer();

		var client = new braintree.api.Client({clientToken: clientTokenFromServer});

		var data = {
			number: card.number,
			cardholderName: 'John Smith',
			expirationMonth: card.exp.month,
			expirationYear: card.exp.year,
			cvv: card.cvc
		};

		client.tokenizeCard(data, function (error, nonce) {
			if(error){
				deferred.reject(error);
			} else {
				deferred.resolve(nonce);
			}
		});

		return deferred.promise;
	};


	return service;
}


module.exports = angular
	.module('braintreeService', [
		require('oclazyload')
	])
	.factory('braintreeSrv', braintreeSrv);

