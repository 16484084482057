'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		onPassRequest: '&'
	};

	directive.controller = 'ForgotFormCtrl';

	directive.controllerAs = 'ffCtrl';

	directive.templateUrl = 'flxForgotForm/flxForgotForm.tpl.html';

	return directive;
};
