'use strict';

module.exports = /*@ngInject*/ function($uibModal, $log) {
	var self = this;

	return function(subscriptionCategory) {

		var modalConfig = {
			controller: 'SubPlansModalCtrl',
			controllerAs: 'spCtrl',
			templateUrl: 'flxSubPlansModal/flxSubPlansModal.tpl.html',
			backdrop: 'static',
			backdropClass: 'page-modal-backdrop',
			windowClass: 'page-modal-window',
			size: 'sub-plans',
			resolve: {
				subscriptionCat: subscriptionCategory
			}
		};

		var instance = $uibModal.open(modalConfig);

		return instance.result;
	};
};
