'use strict';

module.exports = /*@ngInject*/ function($scope, $stateParams, results, channelSrv, playerModal) {
	var vm = this;

	vm.results = results;

	vm.text = $stateParams.text;

	vm.select = function(show) {
		requestStreamUrl(show);
	};

	function requestStreamUrl(show) {
		channelSrv.getStreamUrl(show.id, false)
			.then(function(url) {
				playerModal(show, url);
			});
	}

};