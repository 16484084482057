'use strict';


module.exports = /*@ngInject*/ function($rootScope, $state, AuthService, authModal, loginRedirect, pingSrv, USER_STATUSES, FORMATS, $uibModalStack, $log, timeSrv,$window) {

	var isFirstLoad = true;


	function redirectLoggedInUser(user, toStateName, params) {
		var status = user.subscriptionStatus();
		if (status === USER_STATUSES.SUBSCRIBED) {
			return $state.go(toStateName, params);
		} else {
			return $state.go('user.subscribe');
		}
	}

	$rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {

		
		var modal = $uibModalStack.getTop();
		if (modal) {
			e.preventDefault();
			$uibModalStack.dismissAll();
			return;
		}

		var isUserAuthorized;
		var access;

		if (isFirstLoad) {
			e.preventDefault();
			isFirstLoad = false;

			pingSrv.ping()
				.then(function(data) {

					timeSrv.init(data.date,data.stzo);

					if (data.isLoggedIn) {


						AuthService.getUser()
							.then(function(user) {

								$window.fbq('track', 'LoggedIn');
								$rootScope.currentUser = user;
								//$state.go(toState, toParams);
								$state.go('user.selected',toParams);
							});

					} else {
						$state.go(FORMATS.DEFAULT_STATE);
					}
				}, function() {
					// TODO: handle error
				});

			return;
		}

		access = toState.data.access;
		isUserAuthorized = !access || AuthService.isAuthorized(access);

		if (AuthService.isAuthenticated()) {
			// User is logged-in
			var user = AuthService.currentUser();

			if (toState.name === FORMATS.DEFAULT_STATE) {
				// User is logged-in, but should not go to the Landing page
				e.preventDefault();
				redirectLoggedInUser(user, 'user.selected');
				return;
			}

			if (!isUserAuthorized) {
				// User is logged-in, but not allowed
				e.preventDefault();
				redirectLoggedInUser(user, toState.name, toParams);
				return;
			}
		}

		if (isUserAuthorized) {
			// The user is authorized for the destination state, then continue loading the state
			return;
		}


		// The visitor is not logged-in

		e.preventDefault();

		return authModal()
				.then(function (user, isNewUser) {
					if (user) {
						if (isNewUser) {
							return $state.go('user.subscribe');
						} else {
							return loginRedirect(user, toState, toParams);
						}
					}
					return $state.go(FORMATS.DEFAULT_STATE);
				}, function(error) {
					return $state.go(FORMATS.DEFAULT_STATE);
				});
	});

};