'use strict';

var angular = require('angular');


function elmYPosition(eID) {
	var node = document.getElementById(eID);
	var y = node.offsetTop;
	while (node.offsetParent && node.offsetParent != document.body) {
		node = node.offsetParent;
		y += node.offsetTop;
	}
	return y;
}


// from http://stackoverflow.com/a/26808520

// http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
var requestAnimFrame = (function(){
	return window.requestAnimationFrame
		|| window.webkitRequestAnimationFrame
		|| window.mozRequestAnimationFrame
		|| function( callback ){
			window.setTimeout(callback, 1000 / 60);
		};
})();

function scrollToY(scrollTargetY, speed, easing) {
	// scrollTargetY: the target scrollY property of the window
	// speed: time in pixels per second
	// easing: easing equation to use, see examples http://easings.net/

	scrollTargetY = scrollTargetY || 0;
	speed = speed || 2000;
	easing = easing || 'easeOutSine';

	var scrollY = window.scrollY;
	var currentTime = 0;

	// min time .1, max time .8 seconds
	var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

	// easing equations from https://github.com/danro/easing-js/blob/master/easing.js
	var easingEquations = {
		easeOutSine: function (pos) {
			return Math.sin(pos * (Math.PI / 2));
		},
		easeInOutSine: function (pos) {
			return (-0.5 * (Math.cos(Math.PI * pos) - 1));
		},
		easeInOutQuint: function (pos) {
			if ((pos /= 0.5) < 1) {
				return 0.5 * Math.pow(pos, 5);
			}
			return 0.5 * (Math.pow((pos - 2), 5) + 2);
		}
	};

	// add animation loop
	function tick() {
		currentTime += 1 / 60;

		var p = currentTime / time;
		var t = easingEquations[easing](p);

		if (p < 1) {
			requestAnimFrame(tick);

			window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
		} else {
			window.scrollTo(0, scrollTargetY);
		}
	}

	// call it once to get started
	tick();
}


/*@ngInject*/
function anchorSmoothScroll() {

	this.to = function(eID, topOffset) {

		topOffset = topOffset || 0;

		var stopY = eID ? (elmYPosition(eID) - topOffset) : 0;

		scrollToY(stopY, 1500, 'easeOutSine');

		return;
	};

}


module.exports = angular
	.module('anchorSmoothScroll', [])
	.service('anchorSmoothScroll', anchorSmoothScroll);
