'use strict';

var angular = require('angular');

/*@ngInject*/
function customerSubscriptionSrv(httpAPI, API, subPlanSrv, timeSrv) {
	var service = {};

	service.get = function(id) {
		var requestData = {
			subscription_id: id
		};

		return httpAPI.post(API.CUSTOMER_SUBSCRIPTION.GET, requestData)
			.then(function(data) {
				// TODO
				return data;
			});
	};


	service.sendCancel = function(id) {
		var requestData = {
			subscription_id: id
		};

		return httpAPI.post(API.CUSTOMER_SUBSCRIPTION.SEND_CANCEL_REQUEST, requestData)
			.then(function(data) {
				// TODO
				return data;
			});
	};

	service.undoCancel = function(id) {
		var requestData = {
			subscription_id: id
		};

		return httpAPI.post(API.CUSTOMER_SUBSCRIPTION.UNDO_CANCEL_REQUEST, requestData)
			.then(function(data) {
				// TODO
				return data;
			});
	};

	service.apiToModel = function(data) {
		var plan = subPlanSrv.apiToPlan(data.plan);

		return {
			id: data.id,
			expTime: timeSrv.apiToDate(data.expiration_time),
			expTimeFormated: timeSrv.dateToDateWithoutTime(timeSrv.apiToDate(data.expiration_time)), 
			creationTime: timeSrv.apiToDate(data.created_time),
			cancelationRequestTime: data.cancelation_request_time,
			status: +data.status ? true : false,
			plan: plan
		};
	};

	return service;
}


module.exports = angular
	.module('custSubscriptionService', [
		// 'models.customerSubscription',
		'subPlanService',
		'httpAPI',
		'timeService'
	])
	.factory('customerSubscriptionSrv', customerSubscriptionSrv);

