'use strict';

module.exports = /*@ngInject*/ function( $stateProvider ) {
	$stateProvider.state('user.profile.devices', {
		url: '/devices',
		views: {
			content: {
				controller: 'DevicesCtrl',
				controllerAs: 'devicesCtrl',
				templateUrl: 'profile/devices/devices.tpl.html'
			}
		},
		data: {
			pageTitle: 'Devices',
			pageId: 'devices-page'
		}
	});
};