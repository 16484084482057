'use strict';

var angular = require('angular');
var flxAuthModalController = require('./flxAuthModalController');
var flxAuthModalService = require('./flxAuthModalService');


module.exports = angular
	.module('flxAuthModal', [
		'trioLoginForm',
		'flxRegisterForm',
		'flxForgotForm'
	])
	.controller('AuthModalCtrl', flxAuthModalController)
	.factory('authModal', flxAuthModalService);