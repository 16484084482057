'use strict';

require('./flxAuthModal');
require('./flxChannelLive');
require('./flxForgotForm');
require('./flxLandingVideoBackground');
require('./flxPlayer');
require('./flxPlayerModal');
require('./flxRegisterForm');
require('./flxResetPassForm');
require('./flxShowThumb');
require('./flxSubCatsTable');
require('./flxSubPlansModal');
require('./flxSubPlansTable');
require('./trioAddPayMethodModal');
require('./trioBankCardForm');
require('./trioEpg');
require('./trioLoginForm');
require('./trioShowsCarousel');

require('./flxFlowplayer');
require('./flxSwitch');