'use strict';

var angular = require('angular');

var SECONDS_IN_HOUR = 60 * 60;
var HOURS = 26;
var SECONDS_IN_TIME_FRAME = HOURS * SECONDS_IN_HOUR;
var BEGINNING_OFFSET = 1.5 * SECONDS_IN_HOUR;
var SECONDS_IN_12H = 12 * SECONDS_IN_HOUR;


module.exports = /*@ngInject*/ function($scope, showSrv, timeSrv, $timeout,$log) {
	var vm = this;
	var channelsIds;
	var beginTimeSec;

	var period;

	vm.instanceId = timeSrv.Date().getTime(); // just generate random string
	var newChannelsEvent = 'new-channels' + vm.instanceId;

	//vm.date = new Date();
	vm.date = timeSrv.getServerDate();

	// DATA

	// Channels data
	var stopWatchingChannels = $scope.$watch('channels', function(newChannels) {
		if (newChannels) {
			vm.channels = newChannels;

			channelsIds = vm.channels.map(function(channel) {
				return channel.id;
			});

			//var serverDate = timeSrv.localDateToServerDate(new Date().getTime());
			var serverDate = timeSrv.getServerDate();

			loadSchedules(serverDate, function(nowPositionPx) {
				$scope.setTimeFramePosition(nowPositionPx);
				$scope.setArrowsHeight();
			});
		}
	});

	// On destroy release memory
	$scope.$on('$destroy', function() {
		stopWatchingChannels();
	});


	function loadSchedules(date, callback) {

		period = timeSrv.get24ScheduleTimes(date);
		beginTimeSec = period.begin.getTime() / 1000;

		showSrv.schedules(channelsIds, period.begin, period.end)
			.then(function(data) {
				angular.forEach(vm.channels, function(channel) {
					//console.log(channel);
					var sc = data.schedules[channel.id];
					channel.schedule = sc;
				});

				vm.date = date || timeSrv.getServerDate();

				var eventData = {
					channels: vm.channels
				};

				var nowPositionPx;

				if (date) {

					vm.showMarker = false;

					var calendarNowDiff = date.getTime() / 1000 - data.serverTime.unixtime;
					if (SECONDS_IN_12H < calendarNowDiff) {
						// Future Date
						eventData.futureDate = true;
					} else if (calendarNowDiff < -1 * SECONDS_IN_12H) {
						// Past Date
						eventData.pastDate = true;
					} else {
						// Today
						eventData.serverUnixtime = data.serverTime.unixtime;
						nowPositionPx = getTimePositionInPx(vm.date);
						setNowMarker(nowPositionPx);
					}
				} else {
					// Today
					eventData.serverUnixtime = data.serverTime.unixtime;
					nowPositionPx = getTimePositionInPx(vm.date);
					setNowMarker(nowPositionPx);
				}

				callback && callback(nowPositionPx);

				// Update shows
				$scope.$broadcast(newChannelsEvent, eventData);

			});
	}

	function setNowMarker(nowPositionPx) {
		var timeFrameWidth = $scope.getTimeFrameWidth();
		vm.nowPosition = (nowPositionPx / timeFrameWidth) * 100;
		vm.showMarker = true;
	}

	function getTimePositionInPx(date) {
		var dateSec = date.getTime() / 1000;
		var relativeRowPosition = getRelativePosition(dateSec);
		var showsRowWidth = $scope.getShowsRowWidth();
		return relativeRowPosition * showsRowWidth;
	}

	vm.getWafflePosition = function(show) {
		return getRelativePosition(show.begin+timeSrv.getServerToClientOffsetMs()/1000) * 100;
	};

	vm.getWaffleWidth = function(show) {
		return show.durationSec * 100 / SECONDS_IN_TIME_FRAME;
	};

	vm.selectShow = function(show) {
		$scope.onSelect({show: show});
	};

	function getRelativePosition(elementTimeSec) {
		var position = elementTimeSec - beginTimeSec + BEGINNING_OFFSET;
		return position / SECONDS_IN_TIME_FRAME;
	}

	vm.getWeekDay = function(date) {
		if (!date) {return;}
		var weekDays = ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота'];
		return weekDays[date.getDay()];
	};

	vm.changeDate = function(date) {
		//var serverDate = timeSrv.getServerDate(date.getTime());
		var serverDate = timeSrv.localDateToServerDate(date.getTime());
		loadSchedules(serverDate);
	};

	vm.selectChannel = function(channel) {
		vm.selectShow(channel.currentShow);
	};

	vm.moveBackward = function() {
		$scope.moveTimeFrame(-0.2);
	};

	vm.moveForward = function() {
		$scope.moveTimeFrame(0.2);
	};

};