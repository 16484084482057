'use strict';


module.exports = /*@ngInject*/ function($scope, $state, $window, subPlanSrv, subPlansModal, authModal, FORMATS, channelSrv, timeSrv, $analytics, $log) {
	var vm = this;

	
	vm.showSubPlans = function(category) {
		subPlansModal(category)
			.then(function (plan) {
				// The user selected a plan and wants to subscribe
				return vm.register('user.subscribe', {plan: plan});
			});
	};

	vm.bgvideo = function(){
		var html =
		'<video autoplay preload="auto" loop >'+
		'	<source src="://img1static.bgflix.com/videos/video1WSD.webm" type="video/webm">'+
		'	<source src="://img1static.bgflix.com/videos/video1WSD.mp4" type="video/mp4">'+
		'</video>';

		$log.log(html);
		
		return html;
	};

	vm.register = function(toState, toParams) {
		
		$analytics.eventTrack('Click', {
			category: 'CTA',
			label: 'Try for free'
		});

		
		return authModal('register')
				.then(function (user, isNewUser) {
					if (user) {
						if (isNewUser) {
							toState = 'user.subscribe';
						} else {
							toState = toState || 'user.selected';
						}
						toParams = toParams || {};
						return $state.go(toState, toParams);
					}
					return $state.go(FORMATS.DEFAULT_STATE);
				}, function(error) {
					return $state.go(FORMATS.DEFAULT_STATE);
				});
	};

	channelSrv.list()
		.then(function(data) {
			// Channels
			vm.channels = data.channels;
			//timeSrv.setServerTimezoneOffset(data.serverTime.unixtime);
		});


	function init() {
		subPlanSrv.categories()
			.then(function(categories) {
				vm.categories = categories;
			});
	}

	init();

};