'use strict';


module.exports.names = [
	require('./home').name,
	require('./live').name,
	require('./profile').name,
	require('./schedule').name,
	require('./search').name,
	require('./selected').name,
	require('./subscribe').name
];