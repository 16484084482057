'use strict';

var angular = require('angular');
var selectedController = require('./selectedController');
var states = require('./selectedStates');


module.exports = angular
	.module('states.selected', [
		'showService',
		'trioShowsCarousel',
		'flxPlayerModal',
		'channelService'
	])
	.controller('SelectedCtrl', selectedController)
	.config(states);