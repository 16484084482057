'use strict';

module.exports = /*@ngInject*/ function($scope, currentShow, nextShow, url, isLive, timeSrv) {
	var vm = this;

	if (!currentShow.beginDate) {
		currentShow.beginDate = timeSrv.dateToStr(timeSrv.Date(currentShow.begin * 1000));
	}

	if (nextShow && !nextShow.beginDate) {
		nextShow.beginDate = timeSrv.dateToStr(timeSrv.Date(nextShow.begin * 1000));
	}

	vm.currentShow = currentShow;
	vm.nextShow = nextShow;
	vm.url = url;
	vm.isLive = isLive;

	vm.cancel = $scope.$dismiss;

};