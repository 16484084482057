'use strict';

var angular = require('angular');
var paymentMethodsController = require('./paymentMethodsController');
var states = require('./states');


module.exports = angular
	.module('states.paymentMethods', [
		'paymentMethodService',
		'trioAddPayMethodModal',
		'braintreeService',
		'orderService',
		'toaster'
	])
	.controller('PayMethodsCtrl', paymentMethodsController)
	.config(states);