'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.profile', {
		abstract: true,
		//url: '/profile',
		views: {
			'content@user': {
				templateUrl: 'profile/profile.tpl.html'
			}
		},
		data: {
			pageClass: 'logged-page profile-page',
			access: ['new', 'non-subscribed', 'subscribed']
		},
		redirectTo: 'profile.subscriptions'
	});
};