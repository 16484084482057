'use strict';


module.exports = /*@ngInject*/ function($scope,$log) {
	var vm = this;

	var stopWatchingUrl = $scope.$watch('url', function(newUrl) {
		if (newUrl) {
			if (vm.playerConfig) {
				// Consecutive URLs
				vm.sources = formatSources(newUrl);
			} else {
				// First URL
				vm.playerConfig = configPlayer(newUrl, $scope.isLive);
			}
		}
	});

	$scope.$on('$destroy', function() {
		stopWatchingUrl();
	});

	function formatSources(url) {
		return [
			{
				type: 'application/x-mpegurl',
				src:  url
			}
		];
	}

	function configPlayer(url, isLive) {
		var config = {
			canvas: {
				backgroundColor:'#18b9ed',
				backgroundGradient: 'high'
			},
			clip: {
				sources: formatSources(url)
			},
			embed: false,
			splash: true
		};

		if (isLive) {
			config.live = true;
		}

		return config;
	}

};
