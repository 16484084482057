'use strict';

var angular = require('angular');


module.exports = angular
	.module('flxPlayer', [
		'flxFlowplayer'
	])
	.directive('flxPlayer', require('./flxPlayerDirective'))
	.controller('PlayerCtrl', require('./flxPlayerController'));