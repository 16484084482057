'use strict';

var angular = require('angular');


module.exports = angular
	.module('trioLoginForm', [
		'authService'
	])
	.directive('trioLoginForm', require('./trioLoginFormDirective'))
	.controller('LoginFormCtrl', require('./trioLoginFormController'));