'use strict';

var angular = require('angular');
var find = require('lodash.find');

/*@ngInject*/
function UserModel(USER_STATUSES) {

	function User(config) {
		config = config || {};

		this.address = config.address;
		this.birthday = config.birthday;
		this.city = config.city;
		this.country = config.country;
		this.email = config.email;
		this.trialCredit = config.trialCredit;
		this.gender = config.gender;
		this.name = config.name;
		this.newsByMail = config.newsByMail;
		this.phone = config.phone;
		this.postcode = config.postcode;
		this.productsByMail = config.productsByMail;
		this.subscriptions = config.subscriptions;
	}

	User.prototype.hasInactiveSubscription = function() {
		return hasSubscriptionWithStatus(this.subscriptions, false);
	};

	User.prototype.hasActiveSubscription = function() {
		return hasSubscriptionWithStatus(this.subscriptions, true);
	};

	function hasSubscriptionWithStatus(customerSubscriptions, lookedStatus) {
		if (customerSubscriptions && customerSubscriptions.length) {
			var matchedItem = find(customerSubscriptions, {status: lookedStatus});
			if (matchedItem) {
				// Has at least one customerSubscription with this status
				return true;
			}
			// All customerSubscriptions have the other status
		}
		// There no any customerSubscriptions with this status
		return false;
	}

	User.prototype.subscriptionStatus = function() {
		var customerSubscriptions = this.subscriptions;

		if (customerSubscriptions && customerSubscriptions.length) {
			// Has previous subscriptions

			if (this.hasActiveSubscription()) {
				// Has an active subscription
				return USER_STATUSES.SUBSCRIBED;
			} else {
				// Doesn't have an active subscription
				return USER_STATUSES.NON_SUBSCRIBED;
			}

		} else {
			// Fresh new user, doesn't have any subscriptions
			return USER_STATUSES.NEW;
		}
	};


	return User;
}


module.exports = angular
	.module('models.user', [])
	.factory('User', UserModel);

