'use strict';

var angular = require('angular');

/*@ngInject*/
function fakeHttp($q) {
	return function(data) {
		var deferred = $q.defer();

		deferred.resolve(data);

		return deferred.promise;
	};
}

module.exports = angular.module('fakeHttp', [])
	.factory('fakeHttp', fakeHttp);


