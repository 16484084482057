'use strict';

var angular = require('angular');
var liveController = require('./liveController');
var states = require('./liveStates');


module.exports = angular
	.module('states.live', [
		'channelService',
		'flxPlayer',
		// 'flxSimultaneousShows',
		'timeService',
		'flxPlayerModal',
		'flxChannelLive'
	])
	.controller('LiveCtrl', liveController)
	.config(states);