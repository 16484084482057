'use strict';

var angular = require('angular');


/*@ngInject*/
function showSrv(httpAPI, API, timeSrv, $log) {
	var service = {};

	service.dailyMostWatched = function() {
		return mostWatched('mostWatched24h');
	};

	service.weeklyMostWatched = function() {
		return mostWatched('mostWatchedWeek');
	};

	function mostWatched(period) {
		var requestData = {
			type: period
		};

		return httpAPI.post(API.SHOWS.CHART, requestData)
			.then(function(data) {
				if (angular.isArray(data)) {
					var shows = [];
					angular.forEach(data, function(object) {
						shows.push(service.apiToShow(object));
					});
					return shows;
				}
			});
	}

	service.search = function(str) {
		var requestData = {
			searchKey: str
		};

		return httpAPI.post(API.SHOWS.SEARCH, requestData)
			.then(function(data) {
				return data.map(service.apiToShow);
			});
	};

	service.schedules = function(channelsIds, beginTime, endTime) {
		var requestData = {
			ids: channelsIds,
			scheduleBegin: timeSrv.dateToApi(beginTime),
			scheduleEnd: timeSrv.dateToApi(endTime)
		};

		return httpAPI.post(API.SHOWS.SCHEDULE, requestData)
			.then(function(data) {
				//var serverTime = timeSrv.setServerTime(data.serverTime);
				var schedules = {};
				angular.forEach(data.schedules, function(shows, channelId) {
					shows = shows.map(function(object) {
						return service.apiToShow(object);
					});
					schedules[channelId] = shows;
				});

				return {
					serverTime: data.serverTime,
					schedules: schedules
				};
			});
	};

	service.getShowDetails = function(showId) {
		var requestData = {
			id: showId
		};

		return httpAPI.post(API.SHOWS.DETAILS, requestData)
			.then(function(data) {
				return service.apiToShow(data);
			});
	};


	// Transform from API response data

	service.apiToShow = function(data) {
		var channel;

		if (data.c) {
			channel = {
				id: data.c.i,
				name: data.c.n,
				logo: data.c.l
			};
		}

		return {
			title: data.n,
			begin: data.b,
			beginTimeFormated: timeSrv.dateToTimeStr(timeSrv.Date(data.b * 1000)),
			end: data.e,
			desc: data.de,
			id: data.id,
			durationSec: data.du,
			channel: channel,
			images: data.ss || []
		};
	};


	return service;
}


module.exports = angular
	.module('showService', [
		// 'models.show',
		'httpAPI',
		'timeService'
	])
	.factory('showSrv', showSrv);

