'use strict';

var angular = require('angular');
var dragscroll = require('dragscroll');
var animatedScrollTo = require('../../services-utils-js/animatedScrollTo');

// var DEFAULT_EPG_ID = 'epg1';


module.exports = /*@ngInject*/ function($window, hlStickyElement, $log) {
	var directive = {};
	
	directive.restrict = 'AE';

	directive.scope = {
		channels: '=trioEpg',
		topOffset: '@',
		epgId: '@',
		onSelect: '&'
	};

	directive.controller = 'EpgCtrl';

	directive.controllerAs = 'epgCtrl';

	directive.templateUrl = 'trioEpg/trioEpg.tpl.html';

	directive.link = function(scope, element, attrs, ctrl) {

		var epgDOM = element[0];
		var headRowDOM = epgDOM.getElementsByClassName('epg-head')[0];
		var timeFrameElements = epgDOM.getElementsByClassName('epg-time-frame');
		var timelineElement = epgDOM.getElementsByClassName('epg-timeline')[0];
		var moving = false;
		var isSticky = !attrs.hasOwnProperty('noSticky');

		ctrl.epgId = scope.epgId;

		// UI

		// Drag-n-scroll horizontally
		dragscroll.reset();

		// Sync the horizontal scrolling elements
		angular.element(timeFrameElements).on('scroll', function(e) {
			angular.forEach(timeFrameElements, function(el) {
				if (el !== e.target) {
					el.scrollLeft = e.target.scrollLeft;
				}
			});
		});

		if (isSticky) {
			// Sticky EPG head
			var $headRow = angular.element(headRowDOM);
			var stickyConfig = {
				offsetTop: scope.topOffset || 0,
				container: 'epg1' // TODO: make the id a variable, e.g. scope.epgId || DEFAULT_EPG_ID
			};

			if (stickyConfig.offsetTop > 0) {
				stickyConfig.mediaQuery = 'min-width: 768px';
			}

			var stickyHead = hlStickyElement($headRow, stickyConfig);

			angular.element($window).on('scroll', function() {
				scope.setArrowsHeight();

				scope.$apply(function() {
					stickyHead.draw();
				});
			});
		}


		scope.setArrowsHeight = function() {
			if (!isSticky) { return; }
			var headRect = headRowDOM.getBoundingClientRect();
			var epgRect = epgDOM.getBoundingClientRect();
			var arrBottom = (epgRect.bottom > $window.innerHeight) ? $window.innerHeight : epgRect.bottom;
			var arrHeight = arrBottom - headRect.bottom;
			arrHeight = (arrHeight > 0) ? arrHeight : 0;
			scope.epgArrowsHeight = arrHeight;
		};

		scope.getTimeFrameWidth = function() {
			return timeFrameElements[0].offsetWidth;
		};

		scope.getShowsRowWidth = function() {
			return timelineElement.offsetWidth;
		};

		scope.setTimeFramePosition = function(nowPositionPx) {
			var timeFrameWidth = scope.getTimeFrameWidth();
			var timeFramePosition = nowPositionPx - (timeFrameWidth / 2);
			angular.forEach(timeFrameElements, function(el) {
				el.scrollLeft = timeFramePosition;
			});
		};

		scope.moveTimeFrame = function(step) {
			// TODO: it can be added check for the right end as well
			if (moving || (step < 0 && timeFrameElements[0].scrollLeft <= 0)) { return; }

			var timeFrameWidth = scope.getTimeFrameWidth();

			angular.forEach(timeFrameElements, function(el) {
				var position = el.scrollLeft + (timeFrameWidth * step);

				moving = true;

				animatedScrollTo(el, false, position, 200, function() {
					moving = false;
				});
			});
		};


		// function isHidden() {
		// 	return epgDOM.style.display === 'none';
		// }

		// Calculate sizes on EPG display
		// if (isHidden()) {
		// 	var stopWatchingDisplay = scope.$watch(function() {
		// 		return epgDOM.style.display;
		// 	}, function(newValue) {
		// 		if (newValue && newValue !== 'none') {
		// 			$scope.setTimeFramePosition(nowPositionPx);
		// 			$scope.setArrowsHeight();
		// 			stopWatchingDisplay();
		// 		}
		// 	});
		// }


		// On destroy release memory
		scope.$on('$destroy', function() {
			stickyHead && stickyHead.destroy();
		});

	};
	return directive;
};