'use strict';

var angular = require('angular');


module.exports = angular
	.module('trioBankCardForm', [
		require('angular-credit-cards')
	])
	.directive('trioBankCardForm', require('./trioBankCardFormDirective'))
	.controller('CardFormCtrl', require('./trioBankCardFormController'))
	.filter('yesNo', function () {
		return function (boolean) {
			return boolean ? 'Yes' : 'No';
		};
	})
	;