'use strict';

module.exports = /*@ngInject*/ function($scope, showSrv, channelSrv, playerModal, $log) {
	var vm = this;

	showSrv.dailyMostWatched()
		.then(function(shows) {
			vm.dailyTopShows = shows;
		});

	showSrv.weeklyMostWatched()
		.then(function(shows) {
			vm.weeklyTopShows = shows;
		});

	vm.onSelect = function(show) {
		requestStreamUrl(show);
	};

	function requestStreamUrl(show) {
		channelSrv.getStreamUrl(show.id, false)
			.then(function(url) {
				playerModal(show, url);
			});
	}

};