'use strict';

module.exports = /*@ngInject*/ function($scope) {
	var vm = this;

	vm.select = function(show) {
		$scope.onSelect({show: show});
	};

	var stopWatchingShows = $scope.$watch('shows', function(newShows) {
		if (newShows) {
			vm.shows = newShows;
		}
	});

	$scope.$on('$destroy', function() {
		stopWatchingShows();
	});

};