'use strict';

var angular = require('angular');

/*@ngInject*/
function PaymentMethodModel() {

	function PaymentMethod(config) {
		config = config || {};

		this.token = config.token;
		this.cardType = config.cardType;
		this.logo = config.logo;
		this.default = config.default;
		this.expMonth = config.expMonth;
		this.expYear = config.expYear;
		this.maskedNumber = config.maskedNumber;
	}

	PaymentMethod.prototype.isExpired = function() {
		return Date.now() >= new Date(this.expYear, this.expMonth);
	};

	return PaymentMethod;
}


module.exports = angular
	.module('models.paymentMethod', [])
	.factory('PaymentMethod', PaymentMethodModel);

