'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		categories: '=flxSubCatsTable',
		onSelect: '&'
	};

	directive.controller = 'SCTableCtrl';

	directive.controllerAs = 'sctCtrl';

	directive.templateUrl = 'flxSubCatsTable/flxSubCatsTable.tpl.html';

	return directive;
};
