'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.subscribe', {
		url: '/subscribe',
		views: {
			'content@user': {
				controller: 'SubscribeCtrl',
				controllerAs: 'subscribeCtrl',
				templateUrl: 'subscribe/subscribe.tpl.html'
			}
		},
		params: {
			plan: null
		},
		data: {
			pageTitle: 'Subscribe',
			pageId: 'subscribe-page',
			access: ['new', 'non-subscribed', 'subscribed']
		}
	});
};