'use strict';

module.exports = /*@ngInject*/ function($scope, subscriptionCat, $analytics, $log) {
	var vm = this;

	vm.subscriptionCat = subscriptionCat;

	vm.cancel = $scope.$dismiss;

	vm.onSelectPlan = function(plan) {

		$analytics.eventTrack('Click',{
			category: 'Plans',
			label : 'Plan '+plan.name
		});

		$scope.$close(plan);
	};

};