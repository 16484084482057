'use strict';

var API_ENDPOINT = window.endpoint; //see _scripts.html

module.exports = {
	AUTH: {
		REGISTER: 		API_ENDPOINT + 'userSignup',
		LOGIN: 			API_ENDPOINT + 'userLogin',
		FBLOGINCHECK: 	API_ENDPOINT + 'fbLoginCheck',
		LOGOUT: 		API_ENDPOINT + 'userLogout',
		GET_USER: 		API_ENDPOINT + 'userGet',
		UPDATE: 		API_ENDPOINT + 'userModify',
		PASS_REQUEST: 	API_ENDPOINT + '???',
		CHANGE_PASS: 	API_ENDPOINT + 'userChangePassword',
		FB_LINK: 		API_ENDPOINT + 'fbloginlink'
	},
	PING: 				API_ENDPOINT + 'ping',
	SUBSCRIPTION_PLAN: {
		LIST: 			API_ENDPOINT + 'plansGet'
	},
	ORDER: { 
		PREPARE: 		API_ENDPOINT + 'orderPrepare',
		PENDING: 		API_ENDPOINT + 'orderBasketGet',
		SUBMIT: 		API_ENDPOINT + 'orderSubmit',
		HISTORY: 		API_ENDPOINT + 'ordersGetHistory',
		PROMOCODE_CHECK:API_ENDPOINT + 'promoCodeCheck'
	},
	PAYMENT: {
		CREATE_METHOD: 	API_ENDPOINT + 'paymentMethodCreate',
		GET_TOKEN: 		API_ENDPOINT + 'paymentsGetClientToken',
		METHODS: 		API_ENDPOINT + 'paymentMethodsGet',
		MODIFY: 		API_ENDPOINT + 'paymentMethodModify'
	},
	SHOWS: {
		CHART: 			API_ENDPOINT + 'chartGet',
		SEARCH: 		API_ENDPOINT + 'search',
		SCHEDULE: 		API_ENDPOINT + 'scheduleGetByChannelIds',
		DETAILS: 		API_ENDPOINT + 'scheduleGetDetails'
	},
	CHANNELS: {
		LIST: 			API_ENDPOINT + 'channelsGet',
		STREAM: 		API_ENDPOINT + 'linkGet'
	},
	CUSTOMER_SUBSCRIPTION: {
		GET: 					API_ENDPOINT + 'subscriptionGet',
		SEND_CANCEL_REQUEST: 	API_ENDPOINT + 'subscriptionSendCancelationRequest',
		UNDO_CANCEL_REQUEST: 	API_ENDPOINT + 'subscriptionUndoCancelationRequest'
	}
};