'use strict';

var angular = require('angular');


module.exports = angular
	.module('flxForgotForm', [
		'authService'
	])
	.directive('flxForgotForm', require('./flxForgotFormDirective'))
	.controller('ForgotFormCtrl', require('./flxForgotFormController'));