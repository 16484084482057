'use strict';


module.exports = /*@ngInject*/ function(timeSrv, AuthService, $state, $log, $analytics) {
	var directive = {};

	directive.restrict = 'A';

	directive.require = '^^trioEpg';

	directive.link = function(scope, element, attrs, epgCtrl) {

		var serverUnixtime;
		var instanceId = attrs.flxEpgRows;
		var newChannelsEvent = 'new-channels' + instanceId;
		var getTimeStatusData;
		var showsList = {};

		scope.$on(newChannelsEvent, function(e, data) {
			element.off('click', clickHandler);
			serverUnixtime = data.serverUnixtime;

			if (data.futureDate) {
				getTimeStatusData = function() {
					return {
						className: 'epg-future',
						timeStatus: 1
					};
				};
			} else if (data.pastDate) {
				getTimeStatusData = function() {
					return {
						className: 'epg-rec',
						timeStatus: -1
					};
				};
			} else {
				getTimeStatusData = function(aShow, aServerUnixtime) {
					var className;
					var timeStatus;

					if (aShow.end < aServerUnixtime) {
						// Past show, record
						className = 'epg-rec';
						timeStatus = -1;
					} else if (aServerUnixtime < aShow.begin) {
						// Future show
						className = 'epg-future';
						timeStatus = 1;
					} else {
						// Live
						className = 'epg-live';
						timeStatus = 0;
					}

					return {
						className: className,
						timeStatus: timeStatus
					};
				};
			}

			element[0].innerHTML = makeRows(data.channels);
			element.on('click', clickHandler);
		});

		function findEventElByClass(el, className) {
			if (el.className === 'epg-show-body') {
				return el;
			} else if (el.parentElement) {
				return findEventElByClass(el.parentElement, className);
			} else {
				return null;
			}
		}

		function clickHandler(e) {

			var topEl = e.target;
			var el = findEventElByClass(topEl, 'epg-show-body');
			if (el) {
			
				var showId = el.id.slice(7); // after 'epg-sh-'
				var show = showsList[showId];

				if(!AuthService.isAuthenticated()){
					$analytics.eventTrack('Click',{
						category: 'Prelogin EPG',
						label : 'Schedule '+showId
					});
					$state.go('user.selected');
				} //todo open schedule after login

				if (timeSrv.Date(show.begin * 1000) <= timeSrv.getServerDate().getTime()) {
					epgCtrl.selectShow(show);
				}
			}

			return;
		}

		function makeRows(channels) {
			var htmlArr = [];

			var len = channels.length;
			for (var i = 0; i < len; i++) {
				var channel = channels[i];
				addRow(htmlArr, channel);
			}

			return htmlArr.join('');
		}

		function addRow(htmlArr, channel) {

			htmlArr.push('<div class="epg-shows-row" id="epg-ch-');
			htmlArr.push(channel.id);
			htmlArr.push('">');

			var len = channel.schedule.length;
			for (var i = 0; i < len; i++) {
				var show = channel.schedule[i];
				show.channel = channel;
				showsList[show.id] = show;
				addWaffle(htmlArr, show);
			}

			htmlArr.push('</div>');

			return htmlArr;
		}

		function addWaffle(htmlArr, show) {

			var isUltraShort = false;
			var timeStatusData = getTimeStatusData(show, serverUnixtime);

			htmlArr.push('<a class="epg-show ');

			htmlArr.push(timeStatusData.className);

			if (show.durationSec <= 600) {
				// 10 min
				isUltraShort = true;
				htmlArr.push(' epg-ultra-short');
			} else if (show.durationSec <= 1800) {
				// Short show, 30 min
				htmlArr.push(' epg-short');
			}

			htmlArr.push('" style="left:');
			// insert left value
			htmlArr.push(epgCtrl.getWafflePosition(show));
			htmlArr.push('%;width:');
			// insert width value
			htmlArr.push(epgCtrl.getWaffleWidth(show));
			htmlArr.push('%;"><div class="epg-show-body" id="epg-sh-');
			htmlArr.push(show.id);
			htmlArr.push('">');

			var showBodyContent = (timeStatusData.timeStatus < 1) ? '<i class="fa fa-play-circle"></i>' : '';

			if (!isUltraShort) {
				// insert show begin time
				var beginTime = timeSrv.dateToTimeStr(timeSrv.Date(show.begin * 1000));
				//console.log(beginTime);
				showBodyContent = beginTime + showBodyContent;
				showBodyContent += '<div>';
				// insert show Title
				showBodyContent += show.title;
				showBodyContent += '</div>';
			}

			htmlArr.push(showBodyContent);

			htmlArr.push('</div></a>');

			return htmlArr;
		}

		// On destroy release memory
		scope.$on('$destroy', function() {
			// stopWatchingChannels();
			// TODO: test the memory
			element.off('click', clickHandler);
		});

	};
	return directive;
};
