'use strict';

module.exports = /*@ngInject*/ function( $stateProvider ) {
	$stateProvider.state( 'user.profile.payMethods', {
		url: '/payment-methods',
		views: {
			content: {
				controller: 'PayMethodsCtrl',
				controllerAs: 'pmCtrl',
				templateUrl: 'profile/paymentMethods/paymentMethods.tpl.html'
			}
		},
		data: {
			pageTitle: 'Payment Methods',
			pageId: 'payment-methods-page'
		}
	});
};