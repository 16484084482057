'use strict';

module.exports = /*@ngInject*/ function($scope, AuthService, $analytics, $log) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		if (isValid) {
			register(formData);

			$analytics.eventTrack('Click',{
				category: 'Registration',
				label : 'send'
			});

		}
	};

	function register(data) {
		AuthService.register(data)
			.then(onSuccess, onError);

	}

	function onSuccess(user) {

		$analytics.eventTrack('Processed',{
			category: 'Registration',
			label : 'Success'
		});

		$scope.onRegister({user: user});
	}

	function onError(error) {

		$analytics.eventTrack('Processed',{
			category: 'Registration',
			label : 'Error'
		});
		
		if (error[1] === 'email exists') {
			vm.error = {
				type: 'email',
				msg: 'Вече има потребител, регистриран с този email. Моля, въведете друг email.'
			};
		}
	}

};