'use strict';

  
module.exports = /*@ngInject*/ function($interval, $state, pingSrv, FORMATS, timeSrv) {

	$interval(function() {
		pingSrv.ping()
			.then(function(data) {
				if (data.isLoggedIn && $state.current.name === FORMATS.DEFAULT_STATE) {
					$state.go('user.selected');
				} else if (!data.isLoggedIn && $state.current.data.access && $state.current.data.access.length) {
					$state.go(FORMATS.DEFAULT_STATE);
				}

			});

	}, 60000);

};