'use strict';

var angular = require('angular');


/*@ngInject*/
function flxSwitchDirective() {

	var directive = {};

	directive.restrict = 'A';

	directive.scope = {
		state: '<flxSwitch',
		on: '@',
		off: '@',
		onClick: '&',
		switchData: '<'
	};

	directive.link = link;

	return directive;
}


function link(scope, element, attrs) {

	var state = scope.state;
	toggle(state);

	function toggle(bool) {
		if (bool) {
			element.html(scope.on);
		} else {
			element.html(scope.off);
		}
	}

	element.on('click', function() {
		state = !state;
		toggle(state);
		scope.onClick({
			id: scope.switchData,
			toggle: state
		});
	});

}


module.exports = angular
	.module('flxSwitch', [])
	.directive('flxSwitch', flxSwitchDirective);
