'use strict'

var types = exports.types = require(30)
exports.Type = require(29)

exports.find = function findCardType (callback) {
  for (var typeName in types) {
    var type = types[typeName]
    var result = callback(type)
    if (result) return type
  }
}
