'use strict';

var angular = require('angular');

/*@ngInject*/
function subPlanSrv(httpAPI, API) {
	var service = {};

	service.categories = function() {
		return httpAPI
			.post(API.SUBSCRIPTION_PLAN.LIST, {})
			.then(function(data) {
				if (angular.isArray(data)) {
					var categories = [];

					angular.forEach(data, function(object) {
						categories.push(apiToCategory(object));
					});

					return categories;
				}
			});
	};


	// Transform from API response data

	service.apiToPlan = function(data) {
		var storeItem;

		if (data.store_item) {
			storeItem = {
				name: data.store_item.name,
				price: data.store_item.price,
				isFree: data.free_store_item
			};
		}

		return {
			id: data.id,
			name: data.name,
			realPrice: data.real_price,
			montlyRealPrice: data.real_price_per_month,
			regularPrice: data.regular_price,
			montlyRegularPrice: data.regular_price_per_month,
			period: +data.period,
			trialDays: +data.free_trial,
			categoryName: data.category.name,
			autoRenew: +data.auto_renew ? true : false,
			storeItem: storeItem
		};
	};

	function apiToCategory(data) {
		var plans = [];
		var lowestPlan = {};
		var theMostFreeTrialDays = 0;
		//var highestPlan = {};

		if (data.variants) {
			plans = data.variants.map(function(object) {
				var plan = service.apiToPlan(object);
				if (!lowestPlan.montlyRealPrice || plan.montlyRealPrice < lowestPlan.montlyRealPrice) {
					lowestPlan = plan;
				}
				if (plan.trialDays && theMostFreeTrialDays < plan.trialDays) {
					theMostFreeTrialDays = plan.trialDays;
				}
				return plan;
			});
			lowestPlan.trialDays = theMostFreeTrialDays;
		}

		return {
			name: data.name,
			order: data.ordination,
			plans: plans,
			lowestPlan: lowestPlan
		};
	}


	return service;
}


module.exports = angular
	.module('subPlanService', [
		'httpAPI'
	])
	.factory('subPlanSrv', subPlanSrv);

