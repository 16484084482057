'use strict';

var angular = require('angular');


module.exports = angular
	.module('states.credentials', [
		require('angular-messages'),
		require('angular-validation-match'),
		'authService'
	])
	.controller('CredentialsCtrl', require('./credentialsController'))
	.config(require('./states'));