'use strict';

module.exports = /*@ngInject*/ function($scope, AuthService) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		if (isValid) {
			vm.authError = false;
			login(formData);
		}
	};

	function login(formData) {
		AuthService.login(formData.email, formData.pass)
			.then(onSuccess, onError);
	}

	function onSuccess(user) {
		// TODO:
		// $scope.onLogin(user);
	}

	function onError(error) {
		vm.authError = true;
	}

};