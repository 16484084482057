'use strict';

var angular = require('angular');
var devicesController = require('./devicesController');
var states = require('./states');


module.exports = angular
	.module('states.devices', [])
	.controller('DevicesCtrl', devicesController)
	.config(states);