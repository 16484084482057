'use strict';

var angular = require('angular');


/*@ngInject*/
function orderSrv(httpAPI, API, subPlanSrv, timeSrv,$log) {
	var service = {};

	service.getBasket = function(planId) {
		var requestData = {};
		var url;

		if (planId) {
			// Make a new order
			requestData = {
				plan_id: planId
			};
			url = API.ORDER.PREPARE;
		} else {
			// Get pending items from previous orders
			url = API.ORDER.PENDING;
		}

		return httpAPI.post(url, requestData)
			.then(apiToBasket);
	};

	service.buy = function() {
		return httpAPI.post(API.ORDER.SUBMIT, {});
	};

	service.history = function() {
		return httpAPI.post(API.ORDER.HISTORY, {})
			.then(function(ordersArr) {
				return ordersArr.map(apiToHistoryOrder);
			});
	};


	// Transform from API response data

	function apiToHistoryOrder(data) {
		return {
			id: data.id,
			amount: data.amount,
			status: data.status,
			type: data.type,
			discount: data.discount,
			createdTime: data.create_time ? timeSrv.apiToDate(data.create_time) : null,
			modifiedTime: data.modified_time ? timeSrv.apiToDate(data.modified_time) : null
		};
	}

	function apiToOrder(data) {
		return {
			id: data.order_id,
			amount: data.amount,
			status: data.status,
			type: data.type,
			details: data.details
		};
	}

	function apiToBasket(data) {
		var basketItems = data.items.map(function(item) {
			return apiToOrder(item);
		});

		var subscriptionOrder = data.subscription;
		subscriptionOrder.details = subPlanSrv.apiToPlan(subscriptionOrder.details);

		var basketSubscription = apiToOrder(subscriptionOrder);

		return {
			total: data.total,
			items: basketItems,
			subscription: basketSubscription
		};
	}


	return service;
}


module.exports = angular
	.module('orderService', [
		'subPlanService',
		'httpAPI',
		'timeService'
	])
	.factory('orderSrv', orderSrv);

