'use strict';

module.exports = /*@ngInject*/ function($uibModal, $log) {
	var self = this;

	var modalConfig = {
		controller: 'AddModalCtrl',
		controllerAs: 'amCtrl',
		templateUrl: 'trioAddPayMethodModal/trioAddPayMethodModal.tpl.html'
	};

	return function() {
		var instance = $uibModal.open(modalConfig);

		return instance.result;
	};
};
