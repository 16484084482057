'use strict';

var angular = require('angular');
var dragscroll = require('dragscroll');
var animatedScrollTo = require('../../services-utils-js/animatedScrollTo');


module.exports = /*@ngInject*/ function($timeout, $log) {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		shows: '<',
		onSelect: '&'
	};

	directive.controller = 'ShowsCarouselCtrl';

	directive.controllerAs = 'scCtrl';

	directive.templateUrl = 'trioShowsCarousel/trioShowsCarousel.tpl.html';

	directive.link = function(scope, element, attrs) {

		// Drag-n-scroll horizontally
		dragscroll.reset();

		var moving = false;
		var scrollViewDOM = element.children()[0];
		var lastPosition = 0;
		var listWrapperDOM;
		var positions;

		scope.prev = function() {
			if (scrollViewDOM.scrollLeft === 0) { return; }
			lastPosition = getClosestValues(getPositions(), scrollViewDOM.scrollLeft - 100)[0];
			scrollTo(lastPosition);
		};

		scope.next = function() {
			lastPosition = getClosestValues(getPositions(), scrollViewDOM.scrollLeft + 100)[1];
			scrollTo(lastPosition);
		};

		function getPositions() {
			if (!positions) {
				listWrapperDOM = angular.element(scrollViewDOM).children()[0];
				positions = getItemsPositions(angular.element(listWrapperDOM).children());
			}
			return positions;
		}

		function scrollTo(x) {

			if (moving) { return; }

			moving = true;

			animatedScrollTo(scrollViewDOM, false, x, 200, function() {
				moving = false;
			});
		}

	};

	return directive;
};


function getItemsPositions(items) {
	var arr = [];
	angular.forEach(items, function(item) {
		arr.push(item.offsetLeft);
	});
	return arr;
}

function getClosestValues(a, x) {
	var mid,
		lo = 0,
		hi = a.length - 1;

	while (hi - lo > 1) {
		mid = Math.round((lo + hi)/2);
		if (a[mid] <= x) {
			lo = mid;
		} else {
			hi = mid;
		}
	}

	return [a[lo], a[hi]];
}



