'use strict';

var angular = require('angular');

/*@ngInject*/
function multiStepsSrv() {
	return function() {
		var service = {};

		var currentStep;
		var stepsHistory = [];

		service.back = function() {
			if (stepsHistory.length > 0) {
				currentStep = stepsHistory.pop();
			}
			return currentStep;
		};

		service.goTo = function(newStep) {
			currentStep && stepsHistory.push(currentStep);
			currentStep = newStep;
			return currentStep;
		};

		service.getCurrentStep = function() {
			return currentStep;
		};

		service.hasPreviousSteps = function() {
			return !!stepsHistory.length;
		};


		return service;
	};
}


module.exports = angular
	.module('multiStepsService', [])
	.factory('multiStepsSrv', multiStepsSrv);

