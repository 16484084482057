'use strict';

var isMobile = require('../../services-utils-js/mobileDetector');


module.exports = /*@ngInject*/ function($scope, $window, channelSrv, timeSrv, playerModal) {
	var vm = this;
	var isAnyMobile = isMobile.any();

	vm.currentChannel = {};

	channelSrv.list()
		.then(function(data) {
			vm.channels = data.channels;
			if (!isAnyMobile) { 
				vm.selectChannel(vm.channels[0]);
			}
		});

	vm.selectChannel = function(channel) {
		if (channel.id === vm.currentChannel.id) { return; }

		if (!isAnyMobile) {
			vm.active = channel.id;
		}

		if (channel.currentShow.begin) {
			channel.currentShow.beginTime = timeSrv.dateToTimeStr(timeSrv.Date(channel.currentShow.begin * 1000));
		}

		if (channel.nextShow.begin) {
			channel.nextShow.beginTime = timeSrv.dateToTimeStr(timeSrv.Date(channel.nextShow.begin * 1000));
		}

		vm.currentChannel = channel;

		channelSrv.getStreamUrl(channel.id, true)
			.then(function(url) {

				if (isAnyMobile) {
					channel.currentShow.channel = {
						id: channel.id,
						name: channel.name,
						logo: channel.logo
					};
					playerModal(channel.currentShow, url, true, channel.nextShow);
				} else {
					vm.streamUrl = url;
				}
			});
	};

};