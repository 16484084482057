'use strict';


module.exports = /*@ngInject*/ function(uibDatepickerConfig, uibDatepickerPopupConfig) {

	uibDatepickerConfig.formatYear = 'yy';
	uibDatepickerConfig.startingDay = 1;
	uibDatepickerConfig.showButtonBar = false;
	uibDatepickerConfig.maxMode = 'day';

	uibDatepickerPopupConfig.altInputFormats = ['dd-MMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
	uibDatepickerPopupConfig.datepickerPopup = 'dd-MMM-yyyy';
	// uibDatepickerPopupConfig.showButtonBar = false;

};
