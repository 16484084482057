'use strict';

module.exports = /*@ngInject*/ function($scope, $analytics) {
	var vm = this;

	vm.subscriptionCat = $scope.subscriptionCat;

	vm.deviceLabel = (vm.subscriptionCat.name === 'Smart Web') ? 'Гледай на телевизор - STB' : 'Безплатен Set Top Box';

	vm.select = function(plan) {
		

		$analytics.eventTrack('Click',{
			category: 'Plans',
			label : 'Plan '+plan.name
		});


		$scope.onSelect({plan: plan});
	};

};