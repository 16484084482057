'use strict';

module.exports = /*@ngInject*/ function(timeSrv) {
	var directive = {};

	directive.restrict = 'AE';

	directive.templateUrl = 'flxShowThumb/flxShowThumb.tpl.html';

	directive.link = function(scope, element, attrs) {
		var show = scope[attrs.flxShowThumb];

		show.beginDate = timeSrv.dateToStr(timeSrv.Date(show.begin * 1000));
		show.durationTime = timeSrv.secondsToTime(scope.show.durationSec);

		scope.show = show;
	};

	return directive;
};
