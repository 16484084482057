'use strict';

module.exports = /*@ngInject*/ function($scope, $analytics) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		
		if (isValid) {	
			//console.log("add payment method");
			

			$analytics.eventTrack('Click',{
				category: 'Payment',
				label : 'Add payment method'
			});

			$scope.onSubmit({card: formData});
		}
	};

};