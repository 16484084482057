'use strict';


module.exports = /*@ngInject*/ function($timeout, $location, API, httpAPI, $scope, $rootScope, $log, $state, $stateParams, subPlanSrv, paymentMethodSrv, braintreeSrv, multiStepsSrv, orderSrv, AuthService, $analytics, $window, customerSubscriptionSrv) {
	var vm = this;
	var stepper;

	function init() {
		vm.STEPS_NUMS = {
			RENEW: 10,
			NEW: {
				CAT: 20,
				PLAN: 21,
				ENTER_PROMOCODE: 22
			},
			PM: {
				LIST: 30,
				ADD: 31
			},
			CHECKOUT: 40
		};

		vm.hasHistory = false;

		stepper = multiStepsSrv();

		if ($stateParams.plan) {
			vm.selectPlan($stateParams.plan);
		} else if ($scope.currentUser && $scope.currentUser.hasInactiveSubscription()) {
			// STEP #1
			goTo(vm.STEPS_NUMS.RENEW);
		} else {
			vm.goToSubCategories();
		}
	}


	vm.checkPormocode = function(form,code){
		
		toggleLoading(true);
		httpAPI.post(API.ORDER.PROMOCODE_CHECK, {promocode:code})
			.then(
				function(data) {
					
					var subscription = data;
					if (subscription) {
						$rootScope.currentUser.subscriptions = [];
						$rootScope.currentUser.subscriptions.push(customerSubscriptionSrv.apiToModel(data));
						//$scope.$close($rootScope.currentUser, false);
					}
					toggleLoading(false);
					$location.path('/');

				},
				function(response){
					$log.log(response);
					form.promocode.$invalid = true;
					toggleLoading(false);		
				}
			);

		return;
			
	};

	// STEPS NAVIGATION

	function goTo(step) {
		vm.currentStep = stepper.goTo(step);
		vm.hasHistory = stepper.hasPreviousSteps();
	}

	vm.goBack = function() {
		vm.currentStep = stepper.back();
		vm.hasHistory = stepper.hasPreviousSteps();
	};


	// STEP #2.0
	vm.goToSubCategories = function() {
		if (!vm.subPlanCategories) {
			// Subscription Categories are not loaded
			toggleLoading(true);
			subPlanSrv.categories()
				.then(function(categories) {
					// Wait for categories to load
					vm.subPlanCategories = categories;
					if(categories && categories.length==1){
						vm.selectCat(categories[0]);	
					}else{
						goTo(vm.STEPS_NUMS.NEW.CAT);
					}
					toggleLoading(false);
				});
		}else{
			goTo(vm.STEPS_NUMS.NEW.CAT);
		}
	};

	// STEP #2.1
	vm.goToSubPlans = function() {
		goTo(vm.STEPS_NUMS.NEW.PLAN);
	};

	// STEP #2.2
	vm.goToEnterPromocode = function() {
		goTo(vm.STEPS_NUMS.NEW.ENTER_PROMOCODE);
	};



	// STEP #3
	vm.goToPayMethods = function() {
		if (!vm.paymentMethods) {
			// Payment Methods are not loaded
			toggleLoading(true);
			paymentMethodSrv.list().then(function(pMethods) {
				if (pMethods.length) {
					vm.paymentMethods = pMethods;
					goTo(vm.STEPS_NUMS.PM.LIST);
					toggleLoading(false);
				} else {
					vm.goToNewPayMethod();
				}
			});
		} else {
			goTo(vm.STEPS_NUMS.PM.LIST);
		}
	};

	// STEP #3.1
	vm.goToNewPayMethod = function() {
		toggleLoading(true);
		braintreeSrv.loadJsFile('https://js.braintreegateway.com/v2/braintree.js')
			.then(function() {
				goTo(vm.STEPS_NUMS.PM.ADD);
				toggleLoading(false);
			});
		return;
	};

	// STEP #4
	vm.goToCheckout = function() {
		orderSrv.getBasket(vm.selectedPlan.id)
			.then(function(basket) {
				vm.basket = basket;
			});
		goTo(vm.STEPS_NUMS.CHECKOUT);
	};


	// ACTIONS

	vm.selectCustomerSub = function(subscription) {
		vm.selectPlan(subscription.plan);
	};

	vm.selectCat = function(category) {
		vm.selectedCategory = category;
		if (category.plans.length === 1) {
			vm.selectPlan(category.plans[0]);
		} else {
			vm.goToSubPlans();
		}
	};

	vm.selectPlan = function(plan) {
		vm.selectedPlan = plan;
		vm.goToPayMethods();
	};



	vm.selectMethod = function(paymentMethod) {
		vm.paymentMethod = paymentMethod;
		vm.goToCheckout();
	};

	vm.addPaymentMethod = function(card) {
		var isDefault = false;
		toggleLoading(true);

		return paymentMethodSrv.addPaymentMethod(card, isDefault)
			.then(function(card) {
				vm.paymentMethods && vm.paymentMethods.push(card);
				vm.selectMethod(card);
				toggleLoading(false);
				return true;
			}, function(error) {
				vm.newPMError = error[1];
				toggleLoading(false);
			});
	};


	function toggleLoading(toggle) {
		vm.loading = toggle;
	}


	vm.confirm = function() {
		toggleLoading(true);

		$analytics.eventTrack('Click',{
			category: 'Payment',
			label : 'Submit plan '+vm.basket.subscription.details.name
		});

		//console.log("confiming");
		
		orderSrv.buy()
			.then(function() {

				$log.log();

				if(window.devmode){ //dev mod
					
					$window.google_trackConversion({
						google_conversion_id : 1033023240,
						google_conversion_language : 'en',
						google_conversion_format : '3',
						google_conversion_color : 'ffffff',
						google_conversion_label : 'K1DnCJjupGoQiN7K7AM',
						google_remarketing_only : false
					});

				}else{ //real mode
					
					$window.google_trackConversion({
						google_conversion_id : 874312880,
						google_conversion_language : 'en',
						google_conversion_format : '3',
						google_conversion_color : 'ffffff',
						google_conversion_label : 'Z85wCL-R4GkQsOnzoAM',
						google_remarketing_only : false
					});

				}

				$analytics.eventTrack('Processed',{
					category: 'Payment',
					label : 'Confirm plan '+vm.basket.subscription.details.name
				});
			

				AuthService.getUser()
					.then(function(user) {
						$state.go('user.selected');
					});
			}, function() {
				// TODO: notify the user


				$analytics.eventTrack('Processed',{
					category: 'Payment',
					label : 'Declined plan '+vm.basket.subscription.details.namee
				});
			

				alert('payment error');

			});
	};


	init();

};