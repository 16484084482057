'use strict';

var angular = require('angular');

var SCHEDULE_CHANGE_HOUR = 5;


function addHours(date1, h) {
	var date2 = new Date(date1);
	date2.setUTCHours(date2.getUTCHours() + h);
	return date2;
}


/*@ngInject*/
function timeSrv() {
	var service = {};
	var serverTimezoneOffsetMs;
	var clientTimezoneOffsetMs;
	var serverToClientOffsetMs;
	var serverDate;

	service.Date = function(dateString){
		var d = dateString ? new Date(dateString) : new Date();
		d = d - clientTimezoneOffsetMs+serverTimezoneOffsetMs;
		
		return new Date(d);
	};


	service.get24ScheduleTimes = function(date) {
		date = date || service.Date();
		var scheduleChangeTime = service.Date(date);
		scheduleChangeTime.setHours(SCHEDULE_CHANGE_HOUR, 0, 0, 0);
		var begin;
		var end;

		if (scheduleChangeTime.getTime() <= date.getTime()) {
			begin = scheduleChangeTime;
			end = addHours(scheduleChangeTime, 24);
		} else {
			begin = addHours(scheduleChangeTime, -24);
			end = scheduleChangeTime;
		}

		return {
			begin: begin,
			end: end
		};
	};

	service.init = function(serverTime,serverUtcOffset){
		serverTimezoneOffsetMs = serverUtcOffset * 1000;
		clientTimezoneOffsetMs = - new Date().getTimezoneOffset()*60*1000;
		serverToClientOffsetMs = serverTimezoneOffsetMs - clientTimezoneOffsetMs;
		serverDate = service.Date(serverTime);
		//console.log("init time");
		//console.log("serverDate",serverDate);

		
	};

	service.getServerDate = function(date) {
		return serverDate;
	};

	service.getServerToClientOffsetMs = function(){
		return serverToClientOffsetMs;
	};

	service.secondsToTime = function(seconds) {
		var hours = Math.floor(seconds / 3600);
		var secondsForMinutes = seconds % 3600;
		var minutes = ('0' + Math.floor(secondsForMinutes / 60)).slice(-2);

		return hours + ':' + minutes + ':00';
	};

	// Transformers

	// TODO: refactor the transformers

	service.dateToTimeStr = function(date) {
		var HH = ('0' + date.getHours()).slice(-2),			// Add leading 0.
			mm = ('0' + date.getMinutes()).slice(-2);		// Add leading 0.

		// 'HH:mm'
		return HH + ':' + mm;
	};

	service.dateToDateWithoutTime = function(date){
		var YYYY = date.getFullYear(),
			MM = ('0' + (date.getMonth() + 1)).slice(-2),	
			DD = ('0' + date.getDate()).slice(-2);
		// 'YYYY-MM-DD HH:mm'
		return YYYY + '/' + MM + '/' + DD;
	};

	service.dateToStr = function(date) {
		var YYYY = date.getFullYear(),
			MM = ('0' + (date.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
			DD = ('0' + date.getDate()).slice(-2),			// Add leading 0.
			HH = ('0' + date.getHours()).slice(-2),			// Add leading 0.
			mm = ('0' + date.getMinutes()).slice(-2);		// Add leading 0.

		// 'YYYY-MM-DD HH:mm'
		return DD + '.' + MM + '.' + YYYY + ' - ' + HH + ':' + mm;
	};

/*
	service.setServerTimezoneOffset = function(serverUnixTime) {
		serverTimezoneOffsetMs = service.getTimezoneOffsetMs(serverUnixTime * 1000);
	};
*/
/*
	service.getTimezoneOffsetMs = function(localMs) {
		return new Date(localMs).getTimezoneOffset() * 60 * 1000;
	};
*/

	service.localDateToServerDate = function(localMs) {
		//var localTimezoneOffsetMs = service.getTimezoneOffsetMs(localMs);
		var localTimezoneOffsetMs = clientTimezoneOffsetMs;
		var utcMs = localMs + localTimezoneOffsetMs;
		var serverTimeMs = utcMs + serverTimezoneOffsetMs;
		return new Date(serverTimeMs);
	};

	



	


	service.dateToApi = function(date) {
		var YYYY = date.getFullYear(),
			MM = ('0' + (date.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
			DD = ('0' + date.getDate()).slice(-2),			// Add leading 0.
			HH = ('0' + date.getHours()).slice(-2),			// Add leading 0.
			mm = ('0' + date.getMinutes()).slice(-2),		// Add leading 0.
			ss = ('0' + date.getSeconds()).slice(-2);		// Add leading 0.

		// 'YYYY-MM-DD HH:mm:ss'
		return YYYY + '-' + MM + '-' + DD + ' ' + HH + ':' + mm + ':' + ss;
	};

	// The time from the server comes in format: 'YYYY-MM-DD HH:mm:ss'
	service.apiToDate = function(serverStr) {
		//console.log(serverStr);
		return service.Date(serverStr);
		/*
		var dateTimeArr = serverStr.split(' ');
		var dateArr = dateTimeArr[0].split('-');
		var timeArr = dateTimeArr[1].split(':');

		//return service.Date(dateArr[0], (+dateArr[1] - 1), dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
		return service.Date(dateArr[0], (dateArr[1] - 1), dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
		//return new Date(dateArr[0], (+dateArr[1] - 1), dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
		*/
	};


	return service;
}


module.exports = angular
	.module('timeService', [])
	.factory('timeSrv', timeSrv);

