'use strict';

var angular = require('angular');


module.exports = /*@ngInject*/ function($scope, AuthService, $window) {
	var vm = this;

	vm.user = angular.copy($scope.currentUser);

	vm.submit = function(isValid, formData) {
		$scope.submitted = true;

		if (isValid) {
			AuthService.changePass(formData).then(function() {
				// TODO: add a confimation message for sucessful operation
				$window.alert('Your password was changed sucessfully.');
			}, function(error) {
				$window.alert(error[1]);
			});
		}
	};

};