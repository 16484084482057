'use strict';

var angular = require('angular');
var flxPlayerModalController = require('./flxPlayerModalController');
var flxPlayerModalService = require('./flxPlayerModalService');


module.exports = angular
	.module('flxPlayerModal', [
		'flxPlayer',
		'timeService'
	])
	.controller('PlayerModalCtrl', flxPlayerModalController)
	.factory('playerModal', flxPlayerModalService);