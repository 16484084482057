'use strict';

module.exports = /*@ngInject*/ function($scope, AuthService) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		if (isValid) {
			requestPassReset(formData);
		}
	};

	function requestPassReset(formData) {
		AuthService.requestPassReset(formData.email)
			.then(onSuccess, onError);
	}

	function onSuccess(data) {
		$scope.onPassRequest();
	}

	function onError(error) {}

};