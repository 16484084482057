'use strict';

module.exports = /*@ngInject*/ function($log) {
	var directive = {};

	directive.restrict = 'A';

	directive.scope = {
		url: '<flxPlayer',
		isLive: '<'
	};

	directive.controller = 'PlayerCtrl';

	directive.controllerAs = 'playerCtrl';

	directive.templateUrl = 'flxPlayer/flxPlayer.tpl.html';

	directive.transclude = true;

	return directive;
};
