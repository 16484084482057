'use strict';
 
var angular = require('angular');
 
/*@ngInject*/
function channelSrv(httpAPI, API, showSrv) {
	var service = {};

	service.list = function() {
		return httpAPI.post(API.CHANNELS.LIST, {})
			.then(function(data) {
				if (angular.isArray(data.channels)) {
					var channels = [];
					angular.forEach(data.channels, function(object) {
						channels.push(apiToChannel(object));
					});
					return {
						serverTime: data.serverTime,
						channels: channels
					};
				}
			});
	};

	service.getStreamUrl = function(streamId, isLive) {
		var requestData = {
			id: streamId,
			type: isLive ? 'live' : 'rec'
		};

		return httpAPI.post(API.CHANNELS.STREAM, requestData)
			.then(function(data) {
				return data.listUrl;
			});
	};


	// Transform from API response data

	function apiToChannel(data) {
		return {
			id: data.i,
			name: data.n,
			logo: data.l,
			currentShow: data.nn[0] ? showSrv.apiToShow(data.nn[0]) : {},
			nextShow: data.nn[1] ? showSrv.apiToShow(data.nn[1]) : {}
		};
	}


	return service;
}


module.exports = angular
	.module('channelService', [
		'httpAPI',
		'showService'
	])
	.factory('channelSrv', channelSrv);
