'use strict';

module.exports = /*@ngInject*/ function($uibModal) {

	return function(formType) {

		var modalConfig = {
			controller: 'AuthModalCtrl',
			controllerAs: 'authCtrl',
			templateUrl: 'flxAuthModal/flxAuthModal.tpl.html',
			backdrop: 'static',
			backdropClass: 'page-modal-backdrop',
			windowClass: 'page-modal-window',
			size: 'auth',
			resolve: {
				formType: function() {
					return formType;
				}
			}
		};

		var instance = $uibModal.open(modalConfig);

		return instance.result;
	};
};
