'use strict';


module.exports = /*@ngInject*/ function($scope, channelSrv, playerModal, timeSrv, $log) {
	var vm = this;

	channelSrv.list()
		.then(function(data) {
			// Channels
			vm.channels = data.channels;
			//timeSrv.setServerTimezoneOffset(data.serverTime.unixtime);
		});

	vm.selectShow = function(show) {
		requestStreamUrl(show);
	};

	function requestStreamUrl(show) {
		channelSrv.getStreamUrl(show.id, false)
			.then(function(url) {
				playerModal(show, url);
			});
	}

};