'use strict';

var angular = require('angular');
var subscribeController = require('./subscribeController');
var states = require('./subscribeStates');


module.exports = angular
	.module('states.subscribe', [
		'subPlanService',
		'paymentMethodService',
		'trioBankCardForm',
		'braintreeService',
		'multiStepsService',
		'orderService',
		'authService',
		'flxSubPlansTable'
	])
	.controller('SubscribeCtrl', subscribeController)
	.config(states);