'use strict';

module.exports = /*@ngInject*/ function($scope, paymentMethodSrv, addPayMethodModal, braintreeSrv, orderSrv, toaster, $log) {
	var vm = this;

	vm.makeDefault = function(method) {
		toggleLoading(true);
		paymentMethodSrv.setDefault(method)
			.then(function(methods) {
				vm.paymentMethods = methods;
				toggleLoading(false);
			});
	};

	vm.removeMethod = function(method) {
		// TODO:
	};

	vm.openNewForm = function() {
		toggleLoading(true);
		braintreeSrv.loadJsFile('https://js.braintreegateway.com/v2/braintree.js')
			.then(function() {
				toggleLoading(false);
				addPayMethodModal()
					.then(function (card) {
						// Add
						vm.paymentMethods.push(card);
						toaster.pop('success', 'Success', 'The new payment method has been added successfully.');
					}, function() {
						// Cancel
					});
			});
	};


	function init() {
		var requests = 2;

		function syncRequests() {
			requests--;
			toggleLoading(!!requests);
		}

		toggleLoading(true);

		paymentMethodSrv.list()
			.then(function(paymentMethods) {
				vm.paymentMethods = paymentMethods;
				syncRequests();
			});

		orderSrv.history()
			.then(function(history) {
				vm.history = history;
				$log.log(history);
				syncRequests();
			});
	}

	function toggleLoading(toggle) {
		$scope.loading = toggle;
	}

	init();

};