'use strict';

var angular = require('angular');
var subscriptionsController = require('./subscriptionsController');
var states = require('./states');


module.exports = angular
	.module('states.profile.subscriptions', [
		'custSubscriptionService',
		'flxSwitch'
	])
	.controller('SubscriptionsCtrl', subscriptionsController)
	.config(states);