'use strict';

var angular = require('angular');

require('angular-sticky');


module.exports = angular
	.module('trioEpg', [
		'showService',
		'timeService',
		'hl.sticky'
	])
	.directive('trioEpg', require('./trioEpgDirective'))
	.controller('EpgCtrl', require('./trioEpgController'))
	.directive('flxEpgRows', require('./flxEpgRowsDirective'))
	;