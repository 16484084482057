'use strict';

module.exports = /*@ngInject*/ function($scope, AuthService) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		if (isValid) {
			vm.authError = false;
			//$scope.onSubmit({data: formData});
			login(formData);
		}
	};

	function login(formData) {
		AuthService.login(formData.email, formData.pass, formData.remember)
			.then(onSuccess, onError);
	}

	function onSuccess(user) {
		$scope.onLogin({user: user});
	}

	function onError(error) {
		vm.authError = true;
	}


};