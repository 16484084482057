'use strict';

var angular = require('angular');

var methods = [];

/*@ngInject*/
function paymentMethodSrv(httpAPI, fakeHttp, API, PaymentMethod, braintreeSrv) {
	var service = {};

	service.list = function() {
		if (methods.length) {
			return fakeHttp(methods);
		} else {
			return httpAPI.post(API.PAYMENT.METHODS, {})
				.then(function(data) {
					if (angular.isArray(data)) {
						angular.forEach(data, function(object) {
							methods.push(apiToModel(object));
						});
						return methods;
					}
				});
		}
	};

	service.setDefault = function(method) {
		var params = {
			token: method.token,
			makeDefault: true
		};
		return httpAPI.post(API.PAYMENT.MODIFY, params)
			.then(function() {
				changeDefaultMethod(method.token);
				return methods;
			});
	};

	function changeDefaultMethod(token) {
		var foundOldDefault = false;
		var foundNewDefault = false;

		for (var i = methods.length - 1; i >= 0; i--) {
			var method = methods[i];

			// Set the old default method to non-default
			if (method.default && method.token !== token) {
				method.default = false;
				if (foundNewDefault) {
					return;
				} else {
					continue;
				}
			}

			// Set the new default method
			if (method.token === token) {
				method.default = true;
				if (foundOldDefault) {
					return;
				} else {
					continue;
				}
			}

		}
	}

	service.addPaymentMethod = function(card, isDefault) {
		return getClientTokenFromServer() // Step 1: Get a token from the server
			.then(function(clientTokenFromServer) {
				// Step 2: Tokenize the card data with braintree.js
				return braintreeTokenize(card, clientTokenFromServer);
			}, handleError)
			.then(function(nonce) {
				// Step 3: Send the nonce to the server to save the payment method
				return createPaymentMethod(nonce, isDefault);
			}, handleError);
	};


	// STEPS FOR CREATING A PAYMENT METHOD

	// Step 1
	function getClientTokenFromServer() {
		return httpAPI.post(API.PAYMENT.GET_TOKEN, {});
	}

	// Step 2
	function braintreeTokenize(card, clientTokenFromServer) {
		return braintreeSrv.tokenizeCard(card, clientTokenFromServer);
	}

	// Step 3
	function createPaymentMethod(nonce, isDefault) {
		var data = {
			nonce: nonce,
			default: isDefault
		};
		return httpAPI.post(API.PAYMENT.CREATE_METHOD, data)
			.then(function(card) {
				return apiToModel(card);
			});
	}


	// Transform from API response data
	function apiToModel(data) {
		return new PaymentMethod({
			token: data.token,
			cardType: data.cardType,
			logo: data.logo,
			default: data.default,
			expMonth: data.expirationMonth,
			expYear: data.expirationYear,
			maskedNumber: data.maskedNumber
		});
	}


	function handleError(error) {
		return error;
	}


	return service;
}


module.exports = angular
	.module('paymentMethodService', [
		'models.paymentMethod',
		'httpAPI',
		'fakeHttp',
		'braintreeService'
	])
	.factory('paymentMethodSrv', paymentMethodSrv);

