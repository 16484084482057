'use strict';

function isIEorEDGE(){
	return navigator.appName == 'Microsoft Internet Explorer' || (navigator.appName == 'Netscape' && (navigator.appVersion.indexOf('Trident') > -1 || navigator.appVersion.indexOf('Edge') > -1));
}


if (isIEorEDGE()) {
	// IE or Edge
	document.body.id = 'ie-page';
} else {
	// Browsers

	var angular = require('angular');
	var statesModules = require('./states');

	var dependencies = [
		require('angular-ui-router'),
		require('angular-ui-bootstrap'),
		require('angular-animate'),
		require('angular-resource'),
		require('angular-sanitize'),
		require('../build/js/templates').name,
		'flxAuthModal',
		'loginRedirect',
		'anchorSmoothScroll',
		'pingService',
		'angulartics',
		'angulartics.google.analytics',
		'facebook'
	];

	//console.log(dependencies);

	dependencies = dependencies.concat(statesModules.names);

	angular.module('flxApp', dependencies)
		.constant('API', require('./config/apiEndpoints'))
		.constant('FORMATS', require('./config/formats'))
		.constant('USER_STATUSES', require('./config/userStatuses'))
		.config(require('./config/states'))
		.config(require('./config/httpConfig'))
		.config(require('./config/uiBootstrapConfig'))
		.run(require('./config/stateChangeHandler'))
		.run(require('./config/ping'))
		.controller('MainCtrl', require('./mainController'))
		.filter('flxDate', require('./filters/dateFilter'))
		.filter('flxTime', require('./filters/timeFilter'))
		.filter('numberPart', require('./filters/numberPartFilter'))
		.config(['FacebookProvider',function(FacebookProvider) {
			var myAppId = '668518089983634';

			// You can set appId with setApp method
			// FacebookProvider.setAppId('myAppId');

			/**
			* After setting appId you need to initialize the module.
			* You can pass the appId on the init method as a shortcut too.
			*/
			FacebookProvider.init(myAppId);
		}]);


	// Require all other modules
	require('./components');
	require('./models');
	require('./services-utils');
	require('./services-api');

	// Vendor Components
	require('angularjs-facebook');
	require('angularjs-toaster');
	require('ngsticky');

}

