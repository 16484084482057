'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.profile.credentials', {
		url: '/credentials',
		views: {
			content: {
				controller: 'CredentialsCtrl',
				controllerAs: 'credCtrl',
				templateUrl: 'profile/credentials/credentials.tpl.html'
			}
		},
		data: {
			pageTitle: 'Credentials',
			pageId: 'credentials-page'
		}
	});
};