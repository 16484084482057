'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.schedule', {
		url: '/schedule',
		views: {
			'content@user': {
				controller: 'ScheduleCtrl',
				controllerAs: 'scheduleCtrl',
				templateUrl: 'schedule/schedule.tpl.html'
			}
		},
		data: {
			pageTitle: 'Schedule',
			pageId: 'schedule-page',
			access: ['subscribed']
		}
	});
};