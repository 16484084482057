'use strict';

module.exports = /*@ngInject*/ function($scope, AuthService, $window) {
	var vm = this;

	vm.submit = function(isValid, formData) {
		$scope.submitted = true;

		if (isValid) {
			AuthService.changePass(formData.oldPass, formData.newPass).then(function() {
				// TODO: add a confimation message for sucessful operation
				$window.alert('Your password was changed sucessfully.');
			});
		}
	};

};