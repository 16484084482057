'use strict';


module.exports = /*@ngInject*/ function($filter) {
	return function(value, isFractionalPart) {
		var int = Math.floor(value);
		if (isFractionalPart) {
			return value * 100 - int * 100; // work around for JS quirks with fractions. For our needs thats is enough.
		} else {
			return int;
		}
	};
};
