'use strict';

module.exports = /*@ngInject*/ function($uibModal, $log) {
	var self = this;

	return function(currentShow, url, isLive, nextShow) {

		var modalConfig = {
			controller: 'PlayerModalCtrl',
			controllerAs: 'pmCtrl',
			templateUrl: 'flxPlayerModal/flxPlayerModal.tpl.html',
			backdrop: 'static',
			backdropClass: 'page-modal-backdrop player-modal-backdrop',
			windowClass: 'page-modal-window player-modal-window',
			size: 'player',
			resolve: {
				currentShow: function() {
					return currentShow;
				},
				url: function() {
					return url;
				},
				isLive: function() {
					return isLive;
				},
				nextShow: function() {
					return nextShow;
				}
			}
		};

		var instance = $uibModal.open(modalConfig);

		return instance.result;
	};
};
