'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.live', {
		url: '/live',
		views: {
			'content@user': {
				controller: 'LiveCtrl',
				controllerAs: 'liveCtrl',
				templateUrl: 'live/live.tpl.html'
			}
		},
		data: {
			pageTitle: 'Live',
			pageId: 'live-page',
			access: ['subscribed']
		}
	});
};