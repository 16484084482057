'use strict';

module.exports = /*@ngInject*/ function( $stateProvider ) {
	$stateProvider.state('user.profile.details', {
		url: '/details',
		views: {
			content: {
				controller: 'PersonalDetailsCtrl',
				controllerAs: 'detailsCtrl',
				templateUrl: 'profile/personalDetails/personalDetails.tpl.html'
			}
		},
		data: {
			pageTitle: 'Personal Details',
			pageId: 'personal-details-page'
		}
	});
};