'use strict';


module.exports = /*@ngInject*/ function($scope, $rootScope, $state, $timeout, AuthService, anchorSmoothScroll) {
	var vm = this;

	// User

	$scope.isAuthorized = AuthService.isAuthorized;
	$rootScope.currentUser = AuthService.currentUser() || {};

	vm.logout = function() {
		AuthService.logout();
		$rootScope.currentUser = {};
		$state.go('home');
	};

	vm.getStateName = function(){
		return $state.current.name;
	};
	// Search Form

	vm.submit = function(str) {
		str && $state.go('user.search', {text: str});
	};

	vm.focus = function(id) {
		$timeout(function() {
			document.getElementById(id).focus();
		}, 30);
	};


	// Set page-specific variables - title, id, class
	$scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){

		//var isDefined = angular.isDefined;
		var toStateData = toState.data;

		//vm.pageTitle = toStateData.pageTitle ? toStateData.pageTitle + ' - BgFlix' : 'BgFlix';
		vm.pageTitle = 'BgFlix';

		vm.pageId = toStateData.pageId ? toStateData.pageId : '';

		vm.pageClass = toStateData.pageClass ? toStateData.pageClass : '';

		// $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
	});


	// ScrollTo available for all states
	$scope.scrollTo = function(elementId, offset) {
		anchorSmoothScroll.to(elementId, offset);
	};

};