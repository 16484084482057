'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		onRegister: '&'
	};

	directive.controller = 'RegisterFormCtrl';

	directive.controllerAs = 'rfCtrl';

	directive.templateUrl = 'flxRegisterForm/flxRegisterForm.tpl.html';

	return directive;
};
