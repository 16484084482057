'use strict';

var angular = require('angular');


module.exports = angular
	.module('flxSubPlansModal', [
		'flxSubPlansTable'
	])
	.controller('SubPlansModalCtrl', require('./flxSubPlansModalController'))
	.factory('subPlansModal', require('./flxSubPlansModalService'));