'use strict';

module.exports = /*@ngInject*/ function( $stateProvider ) {
	$stateProvider.state('user.profile.subscriptions', {
		url: '/subscriptions',
		views: {
			content: {
				controller: 'SubscriptionsCtrl',
				controllerAs: 'subsCtrl',
				templateUrl: 'profile/subscriptions/subscriptions.tpl.html'
			}
		},
		data: {
			pageTitle: 'Subscriptions',
			pageId: 'subscriptions-page'
		}
	});
};