'use strict';

var angular = require('angular');


/*@ngInject*/
function AuthService(httpAPI, API, User, customerSubscriptionSrv) {
	var authService = {};
	var user;

	authService.getFbLink = function(){
		return httpAPI.post(API.AUTH.FB_LINK, {});
	};

	authService.register = function(data) {

		var requestData = {
			email: data.email,
			password: data.pass,
			name: data.name,
			phone: data.phone,
			promocode: data.promo,
			productsbymail: data.newsandoffers,
			newsbymail: data.newsandoffers,
			allowtermsandconditions: data.accept
		};

		return httpAPI.post(API.AUTH.REGISTER, requestData)
			.then(handleLogin);
	};

	authService.fbLoginCheck = function(accessToken){

		var requestData = {
			accessToken : accessToken
		};

		return httpAPI.post(API.AUTH.FBLOGINCHECK, requestData)
			.then(handleLogin);
	};

	authService.login = function(email, pass, shouldRemmeber) {

		var requestData = {
			email: email,
			password: pass,
			rememberme: shouldRemmeber
		};

		return httpAPI.post(API.AUTH.LOGIN, requestData)
			.then(handleLogin);
	};

	authService.logout = function() {
		user = null;
		return httpAPI.post(API.AUTH.LOGOUT, {});
	};

	authService.getUser = function() {
		return httpAPI.post(API.AUTH.GET_USER, {})
			.then(handleLogin);
	};

	authService.requestPassReset = function(email) {
		var requestData = {
			email: email
		};
		return httpAPI.post(API.AUTH.PASS_REQUEST, requestData);
	};

	authService.changePass = function(oldPass, newPass) {
		var data = {
			oldPassword: oldPass,
			newPassword: newPass
		};
		return httpAPI.post(API.AUTH.CHANGE_PASS, data);
	};

	authService.updateUser = function(details) {

		var data = {
			name: details.name,
			city: details.city,
			country: details.country,
			gender: details.gender,
			phone: details.phone,
			birthday: details.birthday,
			postcode: details.postcode,
			address: details.address,
			productsbymail: details.productsByMail,
			newsbymail: details.newsByMail
		};

		return httpAPI.post(API.AUTH.UPDATE, data);
	};

	authService.isAuthenticated = function () {
		return !!user;
	};

	authService.currentUser = function() {
		return user;
	};

	authService.isAuthorized = function (authorizedRoles) {
		var usera;
		var status;

		if (!authService.isAuthenticated()) {
			return false;
		}

		if (!angular.isArray(authorizedRoles)) {
			authorizedRoles = [authorizedRoles];
		}

		usera = authService.currentUser();
		status = usera.subscriptionStatus();
		return authorizedRoles.indexOf(status) !== -1;
	};

	function handleLogin(data) {
		user = apiToUser(data);
		return user;
	}


	// Transform from API response data

	function apiToUser(data) {
		var customerSubscriptions = [];

		if (angular.isArray(data.subscriptions)) {
			for (var i = data.subscriptions.length - 1; i >= 0; i--) {
				var subscription = data.subscriptions[i];
				if (subscription) {
					subscription = customerSubscriptionSrv.apiToModel(subscription);
					customerSubscriptions.push(subscription);
				}
			}
		}

		return new User({
			address: data.address,
			birthday: data.birthday,
			city: data.city,
			country: data.country,
			email: data.email,
			trialCredit: +data.free_trail_credit,
			gender: data.gender,
			name: data.name,
			newsByMail: +data.newsbymail ? true : false,
			phone: data.phone,
			postcode: data.postcode,
			productsByMail: +data.productsbymail ? true : false,
			subscriptions: customerSubscriptions
		});
	}


	return authService;
}


module.exports = angular
	.module('authService', [
		'models.user',
		'custSubscriptionService',
		'httpAPI'
	])
	.factory('AuthService', AuthService);



