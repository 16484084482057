'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		onLogin: '&'
	};

	directive.controller = 'LoginFormCtrl';

	directive.controllerAs = 'lfCtrl';

	directive.templateUrl = 'trioLoginForm/trioLoginForm.tpl.html';

	return directive;
};
