'use strict';

var angular = require('angular');
var scheduleController = require('./scheduleController');
var states = require('./scheduleStates');


module.exports = angular
	.module('states.schedule', [
		'channelService',
		'trioEpg',
		'flxPlayerModal',
		'timeService'
	])
	.controller('ScheduleCtrl', scheduleController)
	.config(states);