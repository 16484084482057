'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.selected', {
		url: '/selected',
		views: {
			'content@user': {
				controller: 'SelectedCtrl',
				controllerAs: 'selectedCtrl',
				templateUrl: 'selected/selected.tpl.html'
			}
		},
		data: {
			pageTitle: 'Selected',
			pageId: 'selected-page',
			access: ['subscribed']
		}
	});
};