'use strict';

module.exports = /*@ngInject*/ function() {
	var directive = {};

	directive.restrict = 'AE';

	directive.scope = {
		onSubmit: '&'
	};

	directive.controller = 'ResetFormCtrl';

	directive.controllerAs = 'rfCtrl';

	directive.templateUrl = 'flxResetPassForm/flxResetPassForm.tpl.html';

	return directive;
};
