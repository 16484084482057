'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('home', {
		url: '/',
		views: {
			'main': {
				controller: 'HomeCtrl',
				controllerAs: 'homeCtrl',
				templateUrl: 'home/home.tpl.html'
			}
			// 'content@home': {
			// 	controller: 'HomeCtrl',
			// 	controllerAs: 'homeCtrl',
			// 	templateUrl: 'home/home.tpl.html'
			// },
			// 'header@home': {
			// 	templateUrl: 'header-lp.tpl.html'
			// },
			// 'footer@home': {
			// 	templateUrl: 'footer-lp.tpl.html'
			// }
		},
		data: {
			pageTitle: 'Home',
			pageId: 'home-page'
		}
	});
};