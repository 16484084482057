'use strict';

var angular = require('angular');
var homeController = require('./homeController');
var states = require('./homeStates');

require('angular-sticky');


module.exports = angular
	.module('states.home', [
		'hl.sticky',
		'flxSubCatsTable',
		'flxSubPlansModal',
		'flxLandingVideoBackground',
		'loginRedirect',
		'subPlanService',
		'flxAuthModal',
		'channelService',
		'trioEpg'
	])
	.controller('HomeCtrl', homeController)
	.config(states);