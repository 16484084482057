'use strict';

var angular = require('angular');


/*@ngInject*/
function pingSrv(httpAPI, API) {
	var self = this;

	self.ping = function() {
		return httpAPI.post(API.PING, {})
			.then(function(data) {
				return { isLoggedIn:data.isLoggedIn, date:data.date, stzo:data.stzo };
			});
	};

}


module.exports = angular
	.module('pingService', [
		'httpAPI'
	])
	.service('pingSrv', pingSrv);

