'use strict';

function scrollTo(element, isVertical, toPosition, duration, onFinish) {
	var scrollDirection = isVertical ? 'scrollTop' : 'scrollLeft',
		startPosition = element[scrollDirection],
		distance = toPosition - startPosition,
		interval = 20;

	var animateScroll = function(elapsedTime) {
		elapsedTime += interval;
		var position = easeInOut(elapsedTime, startPosition, distance, duration);
		element[scrollDirection] = position;
		if (elapsedTime < duration) {
			setTimeout(function() {
				animateScroll(elapsedTime);
			}, interval);
		} else {
			onFinish && onFinish();
		}
	};

	animateScroll(0);
}

function easeInOut(currentTime, startPosition, distance, duration) {
	currentTime /= duration / 2;
	if (currentTime < 1) {
		return distance / 2 * currentTime * currentTime + startPosition;
	}
	currentTime -= 1;
	return -distance / 2 * (currentTime * (currentTime - 2) - 1) + startPosition;
}


module.exports = scrollTo;