'use strict';

var angular = require('angular');

/*@ngInject*/
function loginRedirect($state, USER_STATUSES) {
	return function(user, toState, toParams) {
		var status = user.subscriptionStatus();

		if (status === USER_STATUSES.SUBSCRIBED) {
			// Continue
			if (toState) {
				return $state.go(toState.name, toParams);
			} else {
				return $state.go('home');
			}
		} else if (status === USER_STATUSES.NON_SUBSCRIBED) {
			// Go to renew previous subsciption plan
			return $state.go('user.subscribe');
		} else if (status === USER_STATUSES.NEW) {
			// Go to select new subscription plan
			return $state.go('user.subscribe');
		} else {
			// $log.log('This must not happen.');
			return;
		}
	};
}

module.exports = angular
	.module('loginRedirect', [])
	.factory('loginRedirect', loginRedirect);
