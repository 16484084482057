'use strict';

var angular = require('angular');


module.exports = angular
	.module('trioShowsCarousel', [
		'flxShowThumb',
		'timeService'
	])
	.directive('trioShowsCarousel', require('./trioShowsCarouselDirective'))
	.controller('ShowsCarouselCtrl', require('./trioShowsCarouselController'))
	;