'use strict';

module.exports = /*@ngInject*/ function($scope, $analytics) {
	var vm = this;

	vm.select = function(category) {
		//console.log(category);
		
		$analytics.eventTrack('Click',{
			category: 'Plans',
			label : 'Category '+category.name
		});

		$scope.onSelect({category: category});
	};

	var stopWatchingCategories = $scope.$watch('categories', function(newCategories) {
		if (newCategories) {
			vm.categories = newCategories;
		}
	});

	$scope.$on('$destroy', function() {
		stopWatchingCategories();
	});

};