'use strict';

module.exports = /*@ngInject*/ function($stateProvider) {
	$stateProvider.state('user.search', {
		url: '/search/:text',
		views: {
			'content@user': {
				controller: 'SearchCtrl',
				controllerAs: 'searchCtrl',
				templateUrl: 'search/search.tpl.html'
			}
		},
		data: {
			pageTitle: 'Search',
			pageId: 'search-page',
			access: ['subscribed']
		},
		resolve: {
			results: /*@ngInject*/ function(showSrv, $stateParams) {
				return showSrv.search($stateParams.text);
			}
		}
	});
};