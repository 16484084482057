'use strict';

var angular = require('angular');


module.exports = angular
	.module('flxRegisterForm', [
		'authService',
		require('angular-messages')
	])
	.directive('flxRegisterForm', require('./flxRegisterFormDirective'))
	.controller('RegisterFormCtrl', require('./flxRegisterFormController'));