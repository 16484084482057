'use strict';

module.exports = /*@ngInject*/ function($log) {
	var directive = {};

	directive.restrict = 'A';

	directive.scope = {
		channel: '<flxChannelLive'
	};

	directive.templateUrl = 'flxChannelLive/flxChannelLive.tpl.html';
	return directive;
};
