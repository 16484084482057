'use strict';

var angular = require('angular');
var personalDetailsController = require('./personalDetailsController');
var states = require('./states');


module.exports = angular
	.module('states.personalDetails', [])
	.controller('PersonalDetailsCtrl', personalDetailsController)
	.config(states);