'use strict';

module.exports = /*@ngInject*/ function($scope, paymentMethodSrv) {
	var vm = this;

	vm.cancel = $scope.$dismiss;

	vm.onSubmit = function(formData) {
		addPaymentMethod(formData);
	};

	function addPaymentMethod(card) {
		var isDefault = false;
		toggleLoading(true);
		paymentMethodSrv.addPaymentMethod(card, isDefault)
			.then(onSuccess, onError);
	}

	function onSuccess(card) {
		toggleLoading(false);
		$scope.$close(card);
	}

	function toggleLoading(toggle) {
		vm.loading = toggle;
	}

	function onError(error) {
		// TODO: handle error
	}

};