'use strict';


module.exports = /*@ngInject*/ function($stateProvider, $urlRouterProvider) {

	$urlRouterProvider.otherwise( '/' );

	$stateProvider
		.state('user', {
			abstract: true,
			url: '',
			views: {
				'main': {
					templateUrl: 'layout-logged.tpl.html'
				}
				// 'header@user': {
				// 	templateUrl: 'header-logged.tpl.html'
				// }
			},
			data: {
				pageClass: 'logged-page'
			}
		})
		;

};