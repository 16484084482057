'use strict';

var angular = require('angular');


module.exports = angular
	.module('trioAddPayMethodModal', [
		'trioBankCardForm'
	])
	.controller('AddModalCtrl', require('./trioAddPayMethodModalController'))
	.factory('addPayMethodModal', require('./trioAddPayMethodModalService'));