'use strict'

var ccTypes = require(28)
var camel = require(26)
var extend = require(56)

module.exports = extend(ccTypes, {
  get: function getTypeByName (name) {
    return ccTypes.types[camel(name)]
  }
})
