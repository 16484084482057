'use strict';

var angular = require('angular');
var profileController = require('./profileController');
var states = require('./profileStates');


module.exports = angular
	.module('states.profile', [
		require('./credentials').name,
		require('./devices').name,
		require('./paymentMethods').name,
		require('./personalDetails').name,
		require('./subscriptions').name
	])
	.controller('ProfileCtrl', profileController)
	.config(states);