'use strict';

var angular = require('angular');
var flowplayer = require('flowplayer');
var isMobile = require('../services-utils-js/mobileDetector');


/*@ngInject*/
function flxFlowplayerDirective() {

	var directive = {};

	directive.restrict = 'A';

	directive.scope = {
		config: '<',
		newSources: '<'
	};

	directive.link = link;

	return directive;
}


function link(scope, element, attrs) {
	var player;
	var stopWatchingNewSources;
	var currentSource;
	var hasLoaded;

	var stopWatchingConfig = scope.$watch('config', function(newConfig) {
		if (newConfig) {
			// Init the player
			player = flowplayer(element[0], newConfig);

			if(!isMobile.any()) {
				// Not a mobile device
				player.load();
			} else {
				// Mobile
				player.on('load', function(e, ePlayer, eVideo) {
					hasLoaded = true;
				});
			}

			player.on('error', function(e, ePlayer, error) {
				if (error.code === 4 || error.code === 3) { // Video file not found

					// reset state
					ePlayer.error = ePlayer.loading = false;

					// change the skin color and alert the user
					element.removeClass('is-error');

					// load the stream again
					player.load({sources: currentSource});
				}
			});

			stopWatchingConfig();

			// Start watching for new sources after the player inits
			sourcesWatcher();
		}
	});

	function sourcesWatcher() {
		stopWatchingNewSources = scope.$watch('newSources', function(newSources) {

			if (newSources) {
				if (!isMobile.any() || hasLoaded) {
					//console.log("change src", newSources);
					player.unload();
					player.load({sources: newSources});
				} else {
					player.conf.clip.sources = newSources;
				}
				currentSource = newSources;
			}
		});
	}


	scope.$on('$destroy', function() {
		stopWatchingNewSources();
		if (player) {
			player.shutdown();
			player.unload();
		}
	});

}


module.exports = angular
	.module('flxFlowplayer', [])
	.directive('flxFlowplayer', flxFlowplayerDirective);
