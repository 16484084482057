'use strict';

var angular = require('angular');

/*@ngInject*/
function httpAPI($http, $q, $state, authModal) {
	var self = this;

	self.post = function(url, requestData) {
		var deferred = $q.defer();
		var request = {
			method: 'POST',
			url: url,
			data: requestData
		};

		function handleSuccess(response) {
			var data = response.data;
			if (!data || !data[0]) {
				// error
				handleError(response);
				return;
			}

			deferred.resolve(data[1]);
		}

		function handleError(response) {
			if (response.data[1] === 'session error') {
				authModal()
					.then(function (user) {
						if (user) {
							return;
						}
						return $state.go('home');
					}, function(error) {
						return $state.go('home');
					});
			}

			deferred.reject(response.data);
		}

		$http(request).then(handleSuccess, handleError);

		return deferred.promise;
	};

}

module.exports = angular.module('httpAPI', [])
	.service('httpAPI', httpAPI);


